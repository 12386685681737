import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Report } from '../../types/types';
import Button from '../core/button/Button/Button';
import Table, { CustomColumn } from '../core/display/Table/Table';

type TableData = {
  reportee: string;
  reason: string;
  comment: string;
  time: string;
  reporter: string;
  status: string;
  report: Report;
};

interface Props {
  reports: Report[];
}

function ReportsTable({ reports }: Props): JSX.Element {
  const { courseId, assignmentId } = useParams() as {
    courseId: string;
    assignmentId: string;
  };

  const rootPath = `/course/${courseId}/assignment/${assignmentId}`;

  const navigate = useNavigate();

  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableColumns, setTableColumns] = useState<CustomColumn<TableData>[]>([]);

  useEffect(() => {
    const columns: CustomColumn<TableData>[] = [
      { Header: 'Reportee', accessor: 'reportee', className: 'left-align' },
      { Header: 'Reason', accessor: 'reason', className: 'left-align' },
      {
        Header: 'Comment',
        accessor: 'comment',
        className: 'left-align',
        Cell: function render({ cell: { value } }: CellProps<TableData>) {
          if (value) return <div style={{ maxWidth: '200px', pointerEvents: 'none' }}>{value}</div>;
          return value;
        },
      },
      {
        Header: 'Time',
        accessor: 'time',
        className: 'left-align',
        Cell: function render({ cell: { value } }: CellProps<TableData>) {
          return <>{moment(value).format('YYYY-MM-DD HH:mm z')}</>;
        },
      },
      { Header: 'Reporter', accessor: 'reporter', className: 'left-align' },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'left-align',
        Cell: function render({ cell: { row, value } }: CellProps<TableData>) {
          if (row.original.report.pending)
            return (
              <Button variant="rad sm low" href={`${rootPath}/report/${row.original.report.reportId}`} route>
                Pending
              </Button>
            );
          return value;
        },
      },
    ];

    const parseReportsToTableData = (): TableData[] => {
      const dataTable: TableData[] = [];
      reports.forEach((report) => {
        const newRow: TableData = {
          report: report,
          reportee: report.reportee.sortableName,
          reason: report.reason,
          comment: report.comment,
          time: report.createdAt,
          reporter: report.reporter ? report.reporter.sortableName : 'Peerceptiv',
          status: report.pending ? 'Pending' : report.approved ? 'Approved' : report.rejected ? 'Rejected' : 'Error',
        };

        dataTable.push(newRow);
      });
      return dataTable;
    };

    setTableData(parseReportsToTableData());
    setTableColumns(columns);
  }, [reports, rootPath]);

  return (
    <Table
      columns={tableColumns}
      data={tableData}
      sortBy="time"
      title="Reports"
      id="reports-table"
      informOfRow={(row) => navigate(`${rootPath}/report/${row.original.report.reportId}`)}
    >
      <p>
        <b>Click on report to judge</b>
      </p>
    </Table>
  );
}

export default ReportsTable;
