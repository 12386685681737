import _ from 'lodash';
import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { PageQuery, PageQueryParams } from '../../../../types/types';
import { KEY_TABLE_PAGE_SIZE } from '../../../../utils/constants';
import { handleKeySelect, storageAvailable, stringArrayIncludes } from '../../../../utils/functions';
import Button from '../../button/Button/Button';
import JumpButton from '../../button/JumpButton';
import SearchBar from '../../input/SearchBar/SearchBar';
import FilterTab from '../../layout/FilterTab/FilterTab';
import LoadingSpinner from '../../layout/LoadingSpinner/LoadingSpinner';
import Pagination from '../../layout/Pagination/Pagination';
import Icon from '../Icon';
import { AxiosError } from 'axios';

type SortDirection = 'ASC' | 'DESC';

export interface Column<D> {
  accessor: keyof D;
  className?: string;
  Cell?: (datum: D) => React.ReactNode;
  customSort?: string;
  defaultSort?: boolean;
  Header: string | React.ReactNode;
  notSortable?: boolean;
  sortDirection?: SortDirection;
  Style?: (datum: D) => React.CSSProperties;
}

type ClassNames = {
  ctrlsClassName?: string;
  ctrlsSearchClassName?: string;
  ctrlsSelectClassName?: string;
  ctrlsTypesClassName?: string;
  ctrlsWrapperClassName?: string;
  tableClassName?: string;
};

function QueryTable<T>({
  columns,
  classNames,
  ctrlsInsert,
  defaultPageSize,
  hideLimitSelect = false,
  id,
  itemContainerClassName,
  itemViewDefault = false,
  itemViewEnable = false,
  itemViewRender = () => undefined,
  itemViewRenderLoading,
  onRowSelect,
  queryRequest,
  title,
  types,
  updateKey,
  selectedRole,
}: {
  classNames?: ClassNames;
  columns: Column<T>[];
  ctrlsInsert?: React.ReactNode;
  defaultPageSize?: number;
  hideLimitSelect?: boolean;
  id?: string;
  itemContainerClassName?: string;
  itemViewDefault?: boolean;
  itemViewEnable?: boolean;
  itemViewRender?: (item: T, i: number) => React.ReactNode;
  itemViewRenderLoading?: React.ReactNode;
  onRowSelect?: (rowDatum: T) => void;
  queryRequest: (
    params: PageQueryParams,
    successCb: (arg0: PageQuery<T>) => void,
    errorCb?: (err: AxiosError) => boolean,
  ) => void;
  title: string;
  types?: { buttonText: string; type: string | null }[];
  updateKey?: number;
  selectedRole?: string;
}): JSX.Element {
  const uniqueId = useRef(_.uniqueId());

  const [query, setQuery] = useState<PageQuery<T> | null>(null);
  const [limit, setLimit] = useState(defaultPageSize ?? 10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<SortDirection>('ASC');
  const [filterList, setFilterList] = useState<string[]>([]);
  const [itemView, setItemView] = useState(itemViewDefault);
  const [loading, setLoading] = useState(false);

  const debouncedQueryRequest = useMemo(() => _.debounce(queryRequest, 200, { maxWait: 1000 }), [queryRequest]);

  const type = useMemo(
    () => types?.find((type) => stringArrayIncludes(filterList, type.buttonText))?.type ?? null,
    [types, filterList],
  );

  useEffect(() => {
    if (selectedRole != null) {
      setSearch(selectedRole);
    }
  }, [selectedRole]);

  useEffect(() => setPage(1), [queryRequest]);

  useEffect(() => {
    const defaultCol = columns.find((col) => col.defaultSort === true);
    if (defaultCol) {
      setSortBy(defaultCol.customSort ?? String(defaultCol.accessor));
      setSortDirection(defaultCol.sortDirection ?? 'ASC');
    } else if (columns.length > 0) setSortBy(columns[0].customSort ?? String(columns[0].accessor));
  }, [columns]);

  useEffect(() => {
    setLoading(true);
    debouncedQueryRequest(
      {
        limit: String(limit),
        page: String(page - 1),
        search,
        sortBy,
        sortDirection,
        ...((type: string | null | undefined): Record<string, unknown> => (type !== null ? { type } : {}))(type),
      },
      (result) => {
        setQuery(result);
        setLoading(false);
      },
      () => {
        setLoading(false);
        return false;
      },
    );
  }, [debouncedQueryRequest, limit, page, search, sortBy, sortDirection, type, updateKey]);

  useEffect(() => {
    setPage(1);
  }, [search]);

  const handleHeaderCellClick = useCallback(
    (column: Column<T>) => {
      const newSortBy = column.customSort ?? String(column.accessor);
      if (sortBy !== newSortBy || (sortBy === newSortBy && sortDirection === 'DESC')) {
        setSortBy(newSortBy);
        setSortDirection('ASC');
      } else {
        setSortDirection('DESC');
      }
    },
    [sortBy, sortDirection],
  );

  return (
    <OptionalWrapper id={id}>
      <div className={`query-table-ctrls ${classNames?.ctrlsClassName}`}>
        {ctrlsInsert}
        <OptionalWrapper className={classNames?.ctrlsTypesClassName}>
          {types ? (
            <FilterTab label="Table Type" setFilterList={setFilterList} hideLabel>
              {types.map((type, i) => (
                <FilterTab.Button
                  key={type.buttonText}
                  type="radio"
                  name={`query-table-filters-${id}`}
                  defaultChecked={i === 0}
                >
                  {type.buttonText}
                </FilterTab.Button>
              ))}
            </FilterTab>
          ) : null}
        </OptionalWrapper>
        <OptionalWrapper className={classNames?.ctrlsWrapperClassName}>
          <OptionalWrapper className={classNames?.ctrlsSearchClassName}>
            <SearchBar
              placeholder="Search"
              value={search}
              setValue={setSearch}
              resultsLength={query ? query.results.length : 0}
            />
          </OptionalWrapper>
          <OptionalWrapper className={classNames?.ctrlsSelectClassName}>
            {hideLimitSelect ? null : (
              <span className="entries-select-wrapper">
                <select
                  aria-label="Table Page Size"
                  value={limit}
                  onChange={(e) => {
                    setLimit(Number(e.target.value));
                    if (storageAvailable('localStorage'))
                      window.localStorage.setItem(KEY_TABLE_PAGE_SIZE, e.target.value + '');
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>{' '}
                of {query ? query.totalResults : 0} results
              </span>
            )}
            {itemViewEnable ? (
              <Button
                id="view-change-btn"
                className="button-mini"
                classOverride
                onClick={() => setItemView((prev) => !prev)}
                tooltip={itemView ? 'List View' : 'Grid View'}
                ariaLabel={`Change to ${itemView ? 'List View' : 'Grid View'}`}
              >
                <Icon code={itemView ? 'view_list' : 'grid_view'} />
              </Button>
            ) : null}
          </OptionalWrapper>
        </OptionalWrapper>
      </div>

      <JumpButton
        invisible
        id={`pre-table-btn-${uniqueId.current}`}
        targetId={`post-table-btn-${uniqueId.current}`}
        type="focus"
      >
        Skip to after table
      </JumpButton>

      {itemView ? (
        <div className={itemContainerClassName}>
          {query && !loading && query.results ? (
            query.results.length > 0 ? (
              <>{query.results.map(itemViewRender)}</>
            ) : (
              <p className="no-results">No results available</p>
            )
          ) : (
            <>
              {itemViewRenderLoading}
              <LoadingSpinner />
            </>
          )}
        </div>
      ) : (
        <div className="query-table-wrapper">
          <div id={`query-table-heading-${uniqueId.current}`} className="sr-only">
            {title}
          </div>
          <table
            className={`query-table ${classNames?.tableClassName}`}
            aria-describedby={`query-table-heading-${uniqueId.current}`}
          >
            <thead>
              <tr>
                {columns.map((column, i) => (
                  <th
                    key={String(column.accessor) + `-${i}`}
                    className={`${column.className} ${column.notSortable ? 'not-sortable' : ''}`}
                    onClick={() => (column.notSortable ? undefined : handleHeaderCellClick(column))}
                    onKeyDown={
                      column.notSortable ? undefined : (e) => handleKeySelect(e, () => handleHeaderCellClick(column))
                    }
                    tabIndex={column.notSortable ? -1 : 0}
                    aria-label={`${column.Header} ${
                      column.notSortable
                        ? ''
                        : sortBy === (column.customSort ?? column.accessor)
                        ? sortDirection === 'ASC'
                          ? '(Select to sort descending)'
                          : '(Select to sort ascending)'
                        : '(Select to sort ascending)'
                    }`}
                  >
                    <div className="th-wrapper">
                      {column.Header}
                      {sortBy === (column.customSort ?? column.accessor) ? (
                        sortDirection === 'ASC' ? (
                          <Icon className="sort-icon" code="switch_right" label="(Sort Ascending)" />
                        ) : (
                          <Icon className="sort-icon" code="switch_left" label="(Sort Descending)" />
                        )
                      ) : (
                        <Icon className="sort-icon-ghost" code="switch_right" ariaHidden />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {query && !loading ? (
                query.results.length > 0 ? (
                  query.results.map((result, i) => (
                    <tr key={`row-${i}`} onClick={onRowSelect ? () => onRowSelect(result) : undefined}>
                      {columns.map((column) => (
                        <td
                          key={String(column.accessor)}
                          className={column.className}
                          style={column.Style ? column.Style(result) : undefined}
                        >
                          <>{column.Cell ? column.Cell(result) : result[column.accessor as keyof T]}</>
                        </td>
                      ))}
                      {onRowSelect ? (
                        <td
                          className="sr-only sr-show-on-focus select-row-btn"
                          role="button"
                          tabIndex={0}
                          onKeyDown={(e) => handleKeySelect(e, () => onRowSelect(result))}
                        >
                          <>
                            Select Row {i + 1} ({columns[0].Header}:{' '}
                            {columns[0].Cell ? columns[0].Cell(result) : result[columns[0].accessor as keyof T]})
                          </>
                        </td>
                      ) : null}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td align="center" colSpan={99}>
                      No results available
                    </td>
                  </tr>
                )
              ) : (
                <LoadingSpinner />
              )}
            </tbody>
          </table>
        </div>
      )}

      <JumpButton
        invisible
        id={`post-table-btn-${uniqueId.current}`}
        targetId={`pre-table-btn-${uniqueId.current}`}
        type="focus"
      >
        Skip to before table
      </JumpButton>

      <div className="query-table-pagination-wrapper">
        {query && !loading ? (
          <Pagination
            currPage={query.pageNum}
            pageCount={query.finalPageNum + 1}
            nextPage={() => setPage((prev) => Math.min(prev + 1, query.finalPageNum + 1))}
            prevPage={() => setPage((prev) => Math.max(0, prev - 1))}
            goToPage={(page) => setPage(page + 1)}
          />
        ) : null}
      </div>
    </OptionalWrapper>
  );
}

interface OptionalWrapperProps {
  children: React.ReactNode;
  className?: string;
  id?: string;
}

function OptionalWrapper({ children, className, id }: OptionalWrapperProps): JSX.Element {
  if (className || id) {
    return (
      <div className={className} id={id}>
        {children}
      </div>
    );
  }
  return <>{children}</>;
}

export default QueryTable;
