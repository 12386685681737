import React, { useState, useEffect, useCallback } from 'react';
import Table, { getCellWithUnit, CustomColumn } from '../core/display/Table/Table';
import FilterTab from '../core/layout/FilterTab/FilterTab';
import { CellProps, Row } from 'react-table';
import Dropdown from '../core/button/Dropdown/Dropdown';
import _ from 'lodash';
import { Assignment, AssignmentDetailedResults } from '../../types/types';
import { useNavigate, useParams } from 'react-router-dom';

interface Props {
  assignment: Assignment;
  results: AssignmentDetailedResults[];
}

type TableData = {
  assignmentId: string;
  conflict: boolean;
  name: string;
  userId: string;
  submissionId?: string;
} & Record<string, unknown>;

function TeacherIndividualScoresTable({ assignment, results }: Props): JSX.Element {
  const { courseId } = useParams() as { courseId: string };

  const [data, setData] = useState<TableData[]>([]);
  const [columns, setColumns] = useState<CustomColumn<TableData>[]>([]);
  const [colGroups, setColGroups] = useState<string[][]>([]);
  const [toggleHiddensByGroup, setToggleHiddensByGroup] = useState<((value: boolean) => void)[][]>([]);
  const [seeConflicts, setSeeConflicts] = useState(false);
  const [headerIconMap, setHeaderIconMap] = useState<{ [index: string]: string }>({});
  const [filteredList, setFilteredList] = useState<string[]>([]);

  const navigate = useNavigate();

  const mapHeaderToIcon = useCallback((accessor: string, iconCode: string) => {
    setHeaderIconMap((prevState) => {
      const newState = _.clone(prevState);
      newState[accessor] = iconCode;
      return newState;
    });
  }, []);

  const handleRowSelect = useCallback(
    (rowData: TableData) => {
      if (assignment.instructorUpload)
        navigate(`/course/${courseId}/assignment/${rowData.assignmentId}/submission/${rowData.submissionId}`);
      else navigate(`/course/${courseId}/assignment/${rowData.assignmentId}/student/${rowData.userId}`);
    },
    [assignment.instructorUpload, courseId, navigate],
  );

  const informOfRow = useCallback((row: Row<TableData>) => handleRowSelect(row.original), [handleRowSelect]);

  useEffect(() => {
    if (results) {
      const columns: CustomColumn<TableData>[] = [{ Header: 'Name', accessor: 'name', className: 'left-align' }];
      const data: TableData[] = [];
      const colGroups: string[][] = [[], []];
      results.forEach((result: AssignmentDetailedResults, i: number) => {
        const newRow: TableData = {
          name: result.user
            ? result.user.sortableName
            : result.assignmentGroup
            ? result.assignmentGroup.groupName
            : result.name
            ? result.name
            : 'N/A',
          userId: result.userId,
          conflict: result.conflictingRatings,
          assignmentId: result.assignmentId,
          submissionId: result.submission?.submissionId,
        };

        result.averagesByRating.forEach((rating, j: number) => {
          newRow[`rating${j}`] = rating.averageRating;
          if (i === 0) {
            columns.push({
              Header: rating.rating?.name,
              accessor: `rating${j}`,
              Cell: function render({ cell: { value } }: CellProps<TableData>) {
                if (value < 0) return <>N/A</>;
                return getCellWithUnit(value.toFixed(1), ` / ${rating.rating?.maxScore}`);
              },
            });
            colGroups[0].push(`rating${j}`);
            mapHeaderToIcon(`rating${j}`, 'format_list_numbered');
          }
        });

        result.averagesByTag.forEach((tag, j: number) => {
          newRow[`tag${j}`] = tag.averageRating;
          if (i === 0) {
            columns.push({
              Header: tag.tag?.content,
              accessor: `tag${j}`,
              Cell: function render({ cell: { value } }: CellProps<TableData>) {
                if (value < 0) return <>N/A</>;
                return getCellWithUnit(Math.round(value), ` %`);
              },
            });
            colGroups[1].push(`tag${j}`);
            mapHeaderToIcon(`tag${j}`, 'local_offer');
          }
        });

        data.push(newRow);
      });

      setData(data);
      setColumns(columns);
      setColGroups(colGroups);
    }
  }, [results, mapHeaderToIcon]);

  useEffect(() => {
    if (toggleHiddensByGroup.length >= 2) {
      toggleHiddensByGroup[0].forEach((toggleHidden) => toggleHidden(!filteredList.includes('Rating Prompts')));
      toggleHiddensByGroup[1].forEach((toggleHidden) => toggleHidden(!filteredList.includes('Tags')));
    }
  }, [filteredList, toggleHiddensByGroup]);

  const { instructorUpload } = assignment;
  return (
    <Table
      columns={columns}
      data={data}
      sortBy="name"
      title={`${instructorUpload ? 'Submission' : 'Individual'} Scores`}
      colGroups={colGroups}
      setToggleHiddensByGroup={setToggleHiddensByGroup}
      getRowProps={
        seeConflicts
          ? (row) => ({
              style: {
                background: row.original.conflict ? '#ffe3e3' : 'white',
                fontWeight: row.original.conflict ? 'bold' : undefined,
              },
            })
          : undefined
      }
      headerIconMap={headerIconMap}
      informOfRow={informOfRow}
    >
      <FilterTab setFilterList={setFilteredList} label="Show:">
        <FilterTab.Button
          id="btn-ratings"
          type="radio"
          name="individual-scores-display"
          iconCode="format_list_numbered"
          defaultChecked={true}
        >
          Rating Prompts
        </FilterTab.Button>
        <FilterTab.Button
          id="btn-tags"
          type="radio"
          name="individual-scores-display"
          iconCode="local_offer"
          defaultChecked={false}
        >
          Tags
        </FilterTab.Button>
      </FilterTab>
      <div className="card-menu-wrapper">
        <Dropdown>
          <Dropdown.Link
            href="#"
            onClick={() => {
              setSeeConflicts((prevState) => !prevState);
            }}
          >
            {seeConflicts ? 'Hide' : 'See'} Conflicting Ratings
          </Dropdown.Link>
        </Dropdown>
      </div>
    </Table>
  );
}

export default TeacherIndividualScoresTable;
