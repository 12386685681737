import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { outsideClick, handleKeySelect, mod } from '../../../../utils/functions';
import Icon from '../../display/Icon';
import _ from 'lodash';

interface Props {
  align?: 'left' | 'right';
  ariaLabel?: string;
  buttonContent?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  iconCode?: string;
  id?: string;
  minWidth?: string;
  top?: string;
}

function Dropdown({
  align = 'right',
  ariaLabel,
  buttonContent,
  children,
  className,
  disabled,
  iconCode,
  id,
  minWidth,
  top,
}: Props): JSX.Element {
  const uniqueId = useRef(_.uniqueId());
  const containerEl = useRef<HTMLDivElement>(null);
  const contentEl = useRef<HTMLUListElement>(null);
  const buttonEl = useRef<HTMLButtonElement>(null);

  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      if (
        containerEl.current &&
        buttonEl.current?.getAttribute('aria-expanded') === 'true' &&
        outsideClick(e, [containerEl.current])
      ) {
        setShowContent(false);
      }
    };
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && buttonEl.current?.getAttribute('aria-expanded') === 'true') {
        setShowContent(false);
        buttonEl.current?.focus();
      }
    };

    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('keydown', handleEscape);

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('keydown', handleEscape);
    };
  }, []);

  const handleSelect = (e: React.MouseEvent | React.KeyboardEvent) => {
    setShowContent((prevState) => !prevState);
    e.preventDefault();
    e.stopPropagation();
    if (contentEl.current && contentEl.current.style.display === 'none') {
      requestAnimationFrame(() => (contentEl.current?.firstElementChild as HTMLElement | null)?.focus());
    }
  };

  useEffect(() => {
    if (showContent && containerEl.current) {
      const links = containerEl.current.querySelectorAll('a');
      links[0]?.focus();
    }
  }, [showContent]);

  return (
    <div ref={containerEl} id={id} className="peer-dropdown">
      <button
        ref={buttonEl}
        className={className ? className : 'button-mini'}
        type="button"
        disabled={disabled}
        aria-label={ariaLabel ?? (typeof buttonContent === 'string' ? buttonContent : 'Action Menu')}
        aria-haspopup
        aria-expanded={showContent}
        aria-controls={`dropdown-content-${uniqueId.current}`}
        onClick={handleSelect}
        onKeyDown={(e) => handleKeySelect(e, handleSelect)}
      >
        {buttonContent}
        <Icon code={iconCode ? iconCode : 'more_vert'} ariaHidden />
      </button>
      <ul
        role="menu"
        ref={contentEl}
        id={`dropdown-content-${uniqueId.current}`}
        className="content"
        style={{
          display: showContent ? 'block' : 'none',
          right: align === 'right' ? 0 : 'auto',
          left: align === 'left' ? 0 : 'auto',
          top: top ?? undefined,
          minWidth: minWidth,
        }}
        onBlur={(e) => {
          if (contentEl.current && e.relatedTarget && !contentEl.current.contains(e.relatedTarget)) {
            setShowContent(false);
          }
        }}
      >
        {children}
      </ul>
    </div>
  );
}

interface LinkProps {
  children: React.ReactNode;
  className?: string;
  href: string;
  onClick?: (e?: React.MouseEvent) => void;
  route?: boolean;
}

function Link({ children, className, href, onClick, route = false }: LinkProps) {
  const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
    const links = (e.target as HTMLElement).parentElement?.parentElement?.querySelectorAll('a');
    links?.forEach((link, i) => {
      if (link === e.target) {
        switch (e.key) {
          case 'ArrowUp':
            links[mod(i - 1, links.length)]?.focus();
            e.preventDefault();
            break;
          case 'ArrowDown':
            links[mod(i + 1, links.length)]?.focus();
            e.preventDefault();
            break;
          case 'Home':
            links[0]?.focus();
            break;
          case 'End':
            links[links.length - 1]?.focus();
            break;
        }
      }
    });
  }, []);

  return (
    <li role="none">
      {route ? (
        <RouterLink className={className} to={href} onClick={onClick} onKeyDown={handleKeyDown} role="menuitem">
          {children}
        </RouterLink>
      ) : (
        <a className={className} href={href} onClick={onClick} onKeyDown={handleKeyDown} role="menuitem">
          {children}
        </a>
      )}
    </li>
  );
}

Dropdown.Link = Link;

export default Dropdown;
