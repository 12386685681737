import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { RootState } from '../../../store';
import { User } from '../../../types/types';
import { actAs, deleteUserFromCourse, getCourseUser } from '../../../utils/requests';
import Button from '../../core/button/Button/Button';
import ConfirmButton from '../../core/button/ConfirmButton';
import LoadingSpinner from '../../core/layout/LoadingSpinner/LoadingSpinner';

interface Props {
  courseId: string;
  enableRemove?: boolean;
  updateData: () => void;
  userId: string;
}

function ExpandedRosterRow({ courseId, enableRemove = true, updateData, userId }: Props): JSX.Element {
  const dashboardUrl = `/course/${courseId}/assignments`;

  const [user, setUser] = useState<User | null>(null);
  const [loaded, setLoaded] = useState(false);

  const currUser = useSelector((state: RootState) => state.user);

  useEffect(() => {
    getCourseUser(courseId, userId, setUser);
  }, [courseId, userId]);

  useEffect(() => {
    if (user) setLoaded(true);
  }, [user]);

  if (user && loaded) {
    return (
      <div className="expanded-student-row">
        {user ? (
          <div className="action-section">
            <div className="user-details">
              <h1>{user.name}</h1>
              <a href={`mailto:${user.email}`}>{user.email}</a>
            </div>
          </div>
        ) : (
          ''
        )}
        {user.userId !== currUser.userId ? (
          <div className="action-section">
            <Button
              variant="rad sm low"
              onClick={() => {
                actAs(courseId, userId, dashboardUrl, () => {
                  window.location.replace(dashboardUrl);
                });
              }}
              disabled={!user.activeAccount}
              tooltip={!user.activeAccount ? 'Account requires activation' : undefined}
            >
              Act As
            </Button>
          </div>
        ) : null}
        <div className="action-section">
          <RemoveButton userId={userId} courseId={courseId} disabled={!enableRemove} updateData={updateData} />
        </div>
      </div>
    );
  }
  return <LoadingSpinner />;
}

interface RemoveButtonProps {
  courseId: string;
  disabled?: boolean;
  updateData: () => void;
  userId: string;
}

function RemoveButton({ courseId, disabled, updateData, userId }: RemoveButtonProps): JSX.Element {
  const { modalDispatch } = useModalContext();

  return (
    <>
      <ConfirmButton
        modalProps={{
          heading: 'Remove from Course',
          label: 'Are you sure you want to remove this user from the course?',
          buttonText: 'Remove',
          onConfirm: () =>
            deleteUserFromCourse(userId, courseId, () => {
              updateData();
              modalDispatch(
                openModal({
                  heading: 'User Removed',
                  label: 'The user has been removed from the course.',
                  buttonText: 'Continue',
                  cancelHide: true,
                  form: false,
                }),
              );
            }),
        }}
        noModal
      >
        <Button variant="rad sm low" disabled={disabled}>
          Remove From Course
        </Button>
      </ConfirmButton>
    </>
  );
}

export default ExpandedRosterRow;
