import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Grade, AsyncResult, Result } from '../../types/types';
import { CellProps } from 'react-table';
import Histogram from '../core/display/Graph/Histogram';
import Table, { getCellWithUnit, CustomColumn } from '../core/display/Table/Table';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import { getInstructorResultsAll } from '../../utils/requests';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../core/button/Button/Button';
import Icon from '../core/display/Icon';
import FilterTab from '../core/layout/FilterTab/FilterTab';
import { TeacherResultsTabProps } from './TeacherResultsPage';

type SyncTableData = {
  late?: boolean;
  overall?: number;
  override?: boolean;
  peerEval?: number;
  review?: number;
  submission?: number;
  task?: number;
  submissionLatePenalty?: number;
};

type AsyncTableData = {
  taskStatus?: 'Complete' | 'Incomplete';
  reviewStatus?: 'Pass' | 'Fail';
  genGrade?: number;
};

type TableData = {
  assignmentId: string;
  name: string;
  userId: string;
} & SyncTableData &
  AsyncTableData;

function TeacherGradesBreakdown({ assignment, updateKey }: TeacherResultsTabProps): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };

  const [data, setData] = useState<Result[] | null>(null);
  const [graphData, setGraphData] = useState<number[]>([]);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableColumns, setTableColumns] = useState<CustomColumn<TableData>[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [graphDimensions, setGraphDimensions] = useState<{ width: number; height: number } | null>(null);
  const [filterList, setFilterList] = useState<string[]>([]);

  const filteredTableData = useMemo(
    () =>
      tableData.filter((d) => {
        if (filterList.includes('Late Submissions')) return d.late === true;
        return d;
      }),
    [tableData, filterList],
  );

  const lateIcon = (penalty: number) => (
    <Icon
      key="late-icon"
      className="header-icon"
      code="watch_later"
      label={`Late Submission (${penalty > 0 ? `Penalty: -${penalty}%` : 'No Penalty'})`}
      tooltip
    />
  );
  const overrideIcon = useMemo(
    () => <Icon key="override-icon" className="header-icon" code="gavel" label="Overridden" tooltip />,
    [],
  );

  const navigate = useNavigate();

  useEffect(() => getInstructorResultsAll(assignmentId, setData), [updateKey, assignmentId]);

  const handleRowSelect = useCallback(
    (userId: string, assignmentId: string) =>
      navigate(`/course/${courseId}/assignment/${assignmentId}/student/${userId}`),
    [courseId, navigate],
  );

  useEffect(() => {
    const parseDataForGraph = (data: Result[]) => {
      const cumulativeData: number[] = [];
      data.forEach((gradeEntry: { grade: Grade; asyncResult: AsyncResult }) => {
        if (gradeEntry.grade) cumulativeData.push(gradeEntry.grade.overallGrade);
        else if (gradeEntry.asyncResult) cumulativeData.push(gradeEntry.asyncResult.generatedGrade);
      });
      setGraphData(cumulativeData);
    };

    const parseDataForTable = (data: Result[]) => {
      const columns: CustomColumn<TableData>[] = [
        {
          Header: 'Name',
          accessor: 'name',
          className: 'left-align',
        },
      ];
      const dataTable: TableData[] = [];
      data.forEach((result: Result, i: number) => {
        const name = result.user.sortableName;
        const userId = result.userId;
        const assignmentId = result.assignmentId;

        const { grade, asyncResult } = result;

        const newRow: TableData = { name, userId, assignmentId };

        if (grade) {
          newRow[`overall`] = grade.overallGrade;
          newRow[`submission`] = grade.submissionGrade;
          newRow[`review`] = grade.reviewingGrade;
          newRow[`task`] = grade.taskGrade;
          newRow[`peerEval`] = grade.peerEvaluationGrade;
          newRow[`late`] = result.lateSubmission;
          newRow[`override`] = result.override;
          newRow[`submissionLatePenalty`] = grade.submissionLatePenalty;
          if (i === 0) {
            columns.push({
              Header: 'Overall',
              accessor: `overall`,
              Cell: function render({ cell: { row, value } }: CellProps<TableData>) {
                if (row.original.override) {
                  return getCellWithUnit(value.toFixed(2), ' %', [overrideIcon]);
                }
                return getCellWithUnit(value.toFixed(2), ' %');
              },
            });
            if (!assignment.instructorUpload && !assignment.peerEvaluationOnly)
              columns.push({
                Header: 'Submission',
                accessor: `submission`,
                Cell: function render({ cell: { value, row } }: CellProps<TableData>) {
                  if (value < 0) return <>Pending</>;
                  if (row.original.late) {
                    return getCellWithUnit(value.toFixed(2), ' %', [
                      lateIcon(row.original.submissionLatePenalty as number),
                    ]);
                  }
                  return getCellWithUnit(value.toFixed(2), ' %');
                },
              });
            if (!assignment.instructorGradedOnly && !assignment.peerEvaluationOnly) {
              columns.push({
                Header: 'Review',
                accessor: `review`,
                Cell: function render({ cell: { value } }: CellProps<TableData>) {
                  return getCellWithUnit(value.toFixed(2), ' %');
                },
              });
              columns.push({
                Header: 'Task',
                accessor: `task`,
                Cell: function render({ cell: { value } }: CellProps<TableData>) {
                  return getCellWithUnit(value.toFixed(2), ' %');
                },
              });
            }
            if (assignment.peerEvaluationEnabled && !assignment.peerEvaluationOnly) {
              columns.push({
                Header: 'Team Member Evaluation',
                accessor: `peerEval`,
                Cell: function render({ cell: { value } }: CellProps<TableData>) {
                  return getCellWithUnit(value.toFixed(2), ' %');
                },
              });
            }
          }
        } else if (asyncResult) {
          newRow[`taskStatus`] = asyncResult.taskCheck ? 'Complete' : 'Incomplete';
          newRow[`reviewStatus`] = asyncResult.reviewCheck ? 'Pass' : 'Fail';
          newRow[`genGrade`] = asyncResult.generatedGrade;
          if (i === 0) {
            columns.push({ Header: 'Task Status', accessor: `taskStatus` });
            columns.push({ Header: 'Review Status', accessor: `reviewStatus` });
            columns.push({
              Header: 'Grade',
              accessor: `genGrade`,
              Cell: function render({ cell: { value } }: CellProps<TableData>) {
                return <>{value.toFixed(2)} %</>;
              },
            });
          }
        }

        dataTable.push(newRow);
      });

      setTableData(dataTable);
      setTableColumns(columns);
    };

    if (data) {
      parseDataForGraph(data);
      parseDataForTable(data);
      setLoaded(true);
    }
  }, [assignment, data, overrideIcon]);

  const updateGraphDimensions = () => {
    const elem = document.getElementById('breakdown-graph-wrapper');
    if (elem) {
      elem.classList.add('no-children');
      setGraphDimensions({ width: elem.offsetWidth, height: elem.offsetHeight });
      elem.classList.remove('no-children');
    }
  };

  useEffect(() => {
    if (loaded) {
      window.addEventListener('resize', updateGraphDimensions);
      updateGraphDimensions();
    }

    return () => window.removeEventListener('resize', updateGraphDimensions);
  }, [loaded]);

  if (loaded) {
    return (
      <>
        {graphData.length > 0 ? (
          <section>
            <div className="panel-sm panel-white" id="breakdown-graph-card">
              <h2 className="title">Overall Grade Distribution</h2>
              <div className="graph-wrapper" id="breakdown-graph-wrapper">
                {graphData.length > 0 ? (
                  graphDimensions === null || (graphDimensions.width === 0 && graphDimensions.height) === 0 ? (
                    <Button variant="rad low sm" onClick={updateGraphDimensions}>
                      Show Graph
                    </Button>
                  ) : (
                    <Histogram
                      width={graphDimensions.width}
                      height={graphDimensions.height}
                      data={graphData}
                      bins={20}
                    />
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          </section>
        ) : (
          <div className="panel unavailable-card">
            <h2>Overall Grade Distribution Unavailable</h2>
          </div>
        )}

        {tableData.length > 0 ? (
          <section>
            <Table
              columns={tableColumns}
              data={filteredTableData}
              sortBy="name"
              title="Individual Assignment Grades"
              id="individual-assignment-grades-card"
              informOfRow={(row) => handleRowSelect(row.original.userId, row.original.assignmentId)}
            >
              <FilterTab label="Show:" setFilterList={setFilterList}>
                <FilterTab.Button id="btn-all" type="radio" name="grades-filters" defaultChecked={true}>
                  All
                </FilterTab.Button>
                <FilterTab.Button id="btn-late" type="radio" name="grades-filters">
                  Late Submissions
                </FilterTab.Button>
              </FilterTab>
            </Table>
          </section>
        ) : (
          <div className="panel unavailable-card">
            <h2>Individual Assignment Grades Unavailable</h2>
          </div>
        )}
      </>
    );
  }
  return <LoadingSpinner />;
}

export default TeacherGradesBreakdown;
