import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AssignmentProgress, CommentWithReviewComments, RatingResource, RatingScore, Result } from '../../types/types';
import {
  asyncReset,
  getMyGradeResults,
  getResourcesForStudent,
  getStudentCommentsReceivedByPrompt,
  getStudentRatingScores,
} from '../../utils/requests';
import GradesCard from './GradesCard';
import PeerCommentsCard from './PeerCommentsCard';
import ResourcesCard from './ResourcesCard';
import RatingScoresCard from './RatingScoresCard';
import StudentResultsTutorial from '../tutorial/StudentResultsTutorial';

interface Props {
  assignmentProgress: AssignmentProgress;
}

function StudentSubmissionResults({ assignmentProgress }: Props): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };

  const [peerComments, setPeerComments] = useState<CommentWithReviewComments[]>([]);
  const [resources, setResources] = useState<RatingResource[]>([]);
  const [result, setResult] = useState<Result | null>(null);
  const [ratingScores, setRatingScores] = useState<RatingScore[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (assignmentProgress && assignmentProgress.status.hasResult) {
      getMyGradeResults(assignmentId, setResult);
      getStudentRatingScores(assignmentId, setRatingScores);
      getResourcesForStudent(assignmentId, setResources);
      getStudentCommentsReceivedByPrompt(assignmentId, setPeerComments);
    }
  }, [assignmentId, assignmentProgress]);

  const restartAssignment = useCallback(
    () => asyncReset(assignmentId, () => navigate(`/course/${courseId}/assignment/${assignmentId}/dashboard`)),
    [assignmentId, courseId, navigate],
  );

  const { assignment } = assignmentProgress;
  const { hideCommentResults, hideRatingResults } = assignment;

  return (
    <>
      <div className="results-container fadeIn">
        <div className="flex-col primary-section">
          <GradesCard
            assignment={assignmentProgress.assignment}
            result={result ?? undefined}
            canReset={assignmentProgress.status.canReset}
            restartAssignment={restartAssignment}
          />
          <ResourcesCard resources={resources} />
        </div>
        {!assignment.instructorUpload ? (
          <div className="flex-row secondary-section">
            {hideRatingResults === true ? null : (
              <RatingScoresCard ratingScores={ratingScores} result={result ?? undefined} size="long" />
            )}
            {hideCommentResults === true ? null : <PeerCommentsCard peerComments={peerComments} showDialogueButton />}
          </div>
        ) : null}
      </div>
      <StudentResultsTutorial resourcesAvailable={resources.length > 0} />
    </>
  );
}

export default StudentSubmissionResults;
