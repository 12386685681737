import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setReduxAssignment } from '../../actions';
import { selectAssignment, selectCourse } from '../../store/selectors';
import { Assignment, Course, DeepLink } from '../../types/types';
import { DEFAULT_ASSIGNMENT, WIZARD_INIT_ASSIGNMENT_KEY } from '../../utils/constants';
import { deepLinkCallback, getPhaseStatus, setPageTitle, storageAvailable } from '../../utils/functions';
import { copyAssignment, createAssignment, editAssignment, getAssignmentData } from '../../utils/requests';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import AssignmentWizard from './AssignmentWizard';

type AssignmentWizardType = 'new' | 'edit' | 'copy' | 'workflow';

function AssignmentWizardController(): JSX.Element {
  const { courseId, assignmentId, type } = useParams() as {
    courseId: string;
    assignmentId?: string;
    type: AssignmentWizardType;
  };

  const dispatch = useDispatch();

  useEffect(() => setPageTitle(`${type === 'new' ? 'Create' : type === 'edit' ? 'Edit' : 'Copy'} Assignment`), [type]);

  const course = useSelector(selectCourse) as Course;
  const existingAssignment = useSelector(selectAssignment);
  const [initAssignment, setInitAssignment] = useState<Assignment | null>(() => {
    if (existingAssignment && type === 'edit') return initializeAssignment(type, course, existingAssignment);
    if (type === 'new')
      return initializeAssignment(
        type,
        course,
        ((storageAvailable('sessionStorage')
          ? JSON.parse(window.sessionStorage.getItem(WIZARD_INIT_ASSIGNMENT_KEY) ?? 'null')
          : null) as Assignment | null) ?? undefined,
      );
    return null;
  });

  useEffect(() => {
    if (assignmentId && initAssignment === null)
      getAssignmentData(assignmentId, (data) => setInitAssignment(initializeAssignment(type, course, data)));
  }, [initAssignment, type, course, assignmentId]);

  const navigate = useNavigate();

  const onFinish = useCallback(
    (assignmentData: Assignment) => {
      const successCb = (data: Assignment & DeepLink) => {
        dispatch(setReduxAssignment(data));
        deepLinkCallback(data);

        let queryParams = '';
        if (type !== 'edit') queryParams = '?createSuccess=true';
        navigate(`/course/${courseId}/assignment/${data.assignmentId}/dashboard${queryParams}`);
      };
      switch (type) {
        case 'new':
          createAssignment(courseId, assignmentData, successCb);
          break;
        case 'edit':
          editAssignment(assignmentId as string, assignmentData, successCb);
          break;
        case 'copy':
        case 'workflow':
          copyAssignment(assignmentId as string, assignmentData, successCb);
      }
    },
    [type, dispatch, navigate, courseId, assignmentId],
  );

  if (initAssignment)
    return (
      <div className="page assignment-row">
        <AssignmentWizard type={type} initAssignment={initAssignment} onFinish={onFinish} />
      </div>
    );
  return <LoadingSpinner />;
}

export const getDefaultNumberOfReviewers = (assignment: Assignment): number => {
  if (assignment.groupsEnabled) return 2;
  if (assignment.instructorUpload) return 1;
  if (assignment.instructorGradedOnly) return -1;
  if (assignment.liveMode) return 500; //set the numberOfReviewers to 500 to distinguish it is using liveMode
  return 3;
};

const initializeAssignment = (
  type: AssignmentWizardType,
  course: Course,
  initAssignment: Assignment = DEFAULT_ASSIGNMENT,
): Assignment => {
  const assignmentName = type === 'workflow' ? '' : initAssignment.assignmentName;
  const assignmentDescription = type === 'workflow' ? '' : initAssignment.assignmentDescription;

  const numberOfReviewers =
    type === 'new' ? getDefaultNumberOfReviewers(initAssignment) : initAssignment.numberOfReviewers;

  const phaseStatus = getPhaseStatus(initAssignment);
  const currMoment = moment();
  const deadlines = {
    asyncEndDeadline: initAssignment.asyncEndDeadline,
    publicationTime: initAssignment.publicationTime,
    submissionDeadline: initAssignment.submissionDeadline,
    reviewDeadline: initAssignment.reviewDeadline,
    feedbackDeadline: initAssignment.feedbackDeadline,
    peerEvaluationDeadline: initAssignment.peerEvaluationDeadline,
    reflectionDeadline: initAssignment.reflectionDeadline,
  };
  if (type === 'edit') {
    deadlines.asyncEndDeadline =
      initAssignment.asyncEndDeadline !== null
        ? moment(initAssignment.asyncEndDeadline as string | null)
            .tz(course.timeZone)
            .format('YYYY-MM-DD')
        : null;
    deadlines.publicationTime =
      initAssignment.submissionDeadline !== ''
        ? moment(initAssignment.publicationTime).tz(course.timeZone).format('YYYY-MM-DD')
        : currMoment.add(1, 'd').format('YYYY-MM-DD');
    deadlines.submissionDeadline =
      initAssignment.submissionDeadline !== null
        ? moment(initAssignment.submissionDeadline).tz(course.timeZone).format('YYYY-MM-DD')
        : null;
    deadlines.reviewDeadline =
      initAssignment.reviewDeadline !== null
        ? moment(initAssignment.reviewDeadline).tz(course.timeZone).format('YYYY-MM-DD')
        : null;
    deadlines.feedbackDeadline =
      initAssignment.feedbackDeadline !== null
        ? moment(initAssignment.feedbackDeadline).tz(course.timeZone).format('YYYY-MM-DD')
        : null;
    deadlines.peerEvaluationDeadline =
      initAssignment.peerEvaluationDeadline !== null
        ? moment(initAssignment.peerEvaluationDeadline).tz(course.timeZone).format('YYYY-MM-DD')
        : null;
    deadlines.reflectionDeadline =
      initAssignment.reflectionDeadline !== null
        ? moment(initAssignment.reflectionDeadline).tz(course.timeZone).format('YYYY-MM-DD')
        : null;
  } else {
    deadlines.publicationTime = currMoment.add(1, 'd').format('YYYY-MM-DD');
    if (initAssignment.asyncEndDeadline !== null) {
      deadlines.asyncEndDeadline = currMoment.add(14, 'd').format('YYYY-MM-DD');
    } else if (
      initAssignment.submissionDeadline !== null ||
      initAssignment.reviewDeadline !== null ||
      initAssignment.feedbackDeadline !== null ||
      initAssignment.peerEvaluationDeadline !== null ||
      initAssignment.reflectionDeadline != null
    ) {
      if (phaseStatus.submission) deadlines.submissionDeadline = currMoment.add(7, 'd').format('YYYY-MM-DD');
      if (phaseStatus.review) deadlines.reviewDeadline = currMoment.add(5, 'd').format('YYYY-MM-DD');
      if (phaseStatus.feedback) deadlines.feedbackDeadline = currMoment.add(2, 'd').format('YYYY-MM-DD');
      if (phaseStatus.evaluate) deadlines.peerEvaluationDeadline = currMoment.add(5, 'd').format('YYYY-MM-DD');
      if (phaseStatus.reflection) deadlines.reflectionDeadline = currMoment.add(5, 'd').format('YYYY-MM-DD');
    }
  }

  const correctedWeights =
    type !== 'new'
      ? {
          writingGradeWeight: initAssignment.writingGradeWeight,
          reviewingGradeWeight: initAssignment.reviewingGradeWeight,
          taskGradeWeight: initAssignment.taskGradeWeight,
          peerEvaluationGradeWeight: initAssignment.peerEvaluationGradeWeight,
        }
      : initAssignment.peerEvaluationOnly
      ? {
          writingGradeWeight: 0,
          reviewingGradeWeight: 0,
          taskGradeWeight: 0,
          peerEvaluationGradeWeight: 100,
        }
      : initAssignment.instructorGradedOnly
      ? {
          writingGradeWeight: 100,
          reviewingGradeWeight: 0,
          taskGradeWeight: 0,
        }
      : initAssignment.instructorUpload
      ? {
          writingGradeWeight: 0,
          reviewingGradeWeight: 60,
          taskGradeWeight: 40,
        }
      : initAssignment.groupsEnabled && initAssignment.peerEvaluationEnabled
      ? {
          writingGradeWeight: 40,
          reviewingGradeWeight: 20,
          taskGradeWeight: 20,
          peerEvaluationGradeWeight: 20,
        }
      : {};

  return {
    ...initAssignment,
    assignmentName,
    assignmentDescription,
    numberOfReviewers,
    ...deadlines,
    ...correctedWeights,
  };
};

export default AssignmentWizardController;
