import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { clearCourse, clearCourseUser, setReduxCourse, setUser } from '../../actions';
import { selectCourse } from '../../store/selectors';
import { User } from '../../types/types';
import { getCourse, getMyCourseUser } from '../../utils/requests';
import AssignmentController from '../assignment/AssignmentController';
import AssignmentSetupController from '../assignment/AssignmentSetupController';
import Purchasing from '../auth/purchasing/Purchasing';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';

import CourseProgressPage from '../roster/CourseProgressPage';

import RosterPage from '../roster/RosterPage';

import AssignmentList from './AssignmentList';

function CourseController(): JSX.Element {
  const { courseId } = useParams() as { courseId: string };

  const [courseUser, setCourseUser] = useState<User | null>(null);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const course = useSelector(selectCourse);

  useEffect(() => {
    if (courseUser === null || courseId !== courseUser.courseRoleCourseId) {
      getMyCourseUser(courseId, (courseUser) => {
        setCourseUser(courseUser);
        dispatch(setUser(courseUser));
        if (courseUser.courseStudentPurchaseRequired && !location.pathname.includes(`/course/${courseId}/purchase`))
          navigate(`/course/${courseId}/purchase`);
      });
    }
  }, [courseId, courseUser, dispatch, location, navigate]);

  useEffect(() => {
    if (course === null || courseId !== course.courseId) getCourse(courseId, (data) => dispatch(setReduxCourse(data)));
  }, [course, courseId, dispatch]);

  useEffect(
    () => () => {
      // On unmount, clear course data from stored user object
      dispatch(clearCourse());
      dispatch(clearCourseUser());
    },
    [dispatch],
  );

  if (course && courseUser) {
    if (courseUser.courseStudentPurchaseRequired === true) {
      return (
        <Routes>
          <Route path={`/purchase/*`} element={<Purchasing path="/course/:courseId/purchase/*" />} />
        </Routes>
      );
    } else {
      return (
        <Routes>
          <Route path={`/assignment/setup/*`} element={<AssignmentSetupController />} />
          <Route path={`/assignment/:assignmentId/*`} element={<AssignmentController />} />

          <Route path={`/purchase/*`} element={<Purchasing path="/course/:courseId/purchase/*" />} />
          <Route path={`/assignments`} element={<AssignmentList />} />
          <Route path={`/roster`} element={<RosterPage />} />
          <Route path={`/progress`} element={<CourseProgressPage />} />
        </Routes>
      );
    }
  }
  return <LoadingSpinner />;
}

export default CourseController;
