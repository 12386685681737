import React from 'react';
import { Tooltip, TooltipContent, TooltipTrigger } from '../layout/Tooltip/Tooltip';

interface Props {
  ariaHidden?: boolean;
  className?: string;
  code: string;
  color?: string;
  id?: string;
  label?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  style?: React.CSSProperties;
  tooltip?: boolean;
}

function Icon({ ariaHidden, className, code, color, id, label, onClick, style, tooltip }: Props): JSX.Element {
  const props = {
    className: `material-icons ${className ?? ''}`,
    id,
    onClick,
    style: { color, ...style },
    role: label !== undefined ? 'img' : undefined,
    'aria-label': label,
    'aria-hidden': ariaHidden,
  };

  if (tooltip) {
    return (
      <Tooltip>
        <TooltipTrigger tag="i" {...props} tabIndex={0} ariaLabel="Icon">
          {code}
        </TooltipTrigger>
        <TooltipContent>{label}</TooltipContent>
      </Tooltip>
    );
  }
  return <i {...props}>{code}</i>;
}

export default Icon;
