import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../../store';
import { selectAssignment, selectCourse } from '../../../../store/selectors';

import StudentSidebar from './StudentSidebar';
import TeacherSidebar from './TeacherSidebar';

const pathRoot = '/course/:courseId/assignment/:assignmentId';
export const sidebarPaths = [
  `${pathRoot}/dashboard`,
  `${pathRoot}/edit`,
  `${pathRoot}/results/*`,
  `${pathRoot}/rubric`,
  `${pathRoot}/rubric/*`,
  `${pathRoot}/submission/:submissionId?`,
  `${pathRoot}/instructor-submission`,
  `${pathRoot}/progress`,
  `${pathRoot}/report/:reportId`,
  `${pathRoot}/student/:userId`,
  `${pathRoot}/groups`,
  `${pathRoot}/groups/:pathExtension`,
  `${pathRoot}/groups/:groupId/add`,
  `${pathRoot}/circles`,
  `${pathRoot}/circles/:pathExtension`,
  `${pathRoot}/circles/:groupId/add`,
  `${pathRoot}/manage-reviews`,
];

function MainSidebar(): JSX.Element {
  const { assignmentId, courseId } = useParams() as { courseId: string; assignmentId: string };

  const user = useSelector((state: RootState) => state.user);

  const course = useSelector(selectCourse);
  const assignment = useSelector(selectAssignment);

  if (user !== null && course !== null && assignment !== null) {
    switch (user.courseRole) {
      case 'STUDENT':
        return <StudentSidebar user={user} course={course} assignment={assignment} />;
      case 'TEACHER':
        return <TeacherSidebar user={user} course={course} assignment={assignment} />;
    }
  }

  return courseId !== '' && assignmentId !== '' ? <div className="peer-sidebar-skeleton" /> : <></>;
}

export default MainSidebar;
