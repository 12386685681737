import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../store';
import { AssignmentProgress, AverageRating, CommentWithReviewComments } from '../../types/types';
import { getAssignmentEvalAverages, getStudentEvalCommentsReceivedByPrompt } from '../../utils/requests';
import EvalAverageScoresCard from './EvalAverageScoresCard';
import GradesCard from './GradesCard';
import PeerCommentsCard from './PeerCommentsCard';

interface Props {
  assignmentProgress: AssignmentProgress;
}

function StudentEvalResults({ assignmentProgress }: Props): JSX.Element {
  const { assignmentId } = useParams() as { assignmentId: string };

  const [evalAverages, setEvalAverages] = useState<AverageRating[]>([]);
  const [peerComments, setPeerComments] = useState<CommentWithReviewComments[]>([]);

  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (assignmentProgress && assignmentProgress.status.hasResult) {
      if (assignmentProgress.assignment?.peerEvaluationEnabled) {
        getAssignmentEvalAverages(assignmentId, user.userId, setEvalAverages);
        getStudentEvalCommentsReceivedByPrompt(assignmentId, setPeerComments);
      }
    }
  }, [assignmentId, assignmentProgress, user]);

  const { assignment } = assignmentProgress;
  const { hideCommentResults, hideRatingResults } = assignment;

  return (
    <div className="results-container fadeIn">
      <div className="flex-row secondary-section">
        <GradesCard
          assignment={assignmentProgress.assignment}
          canReset={false}
          result={assignmentProgress.result ?? undefined}
        />
        {hideRatingResults === true ? null : <EvalAverageScoresCard evalAverages={evalAverages} />}
        {hideCommentResults === true ? null : <PeerCommentsCard peerComments={peerComments} />}
      </div>
    </div>
  );
}

export default StudentEvalResults;
