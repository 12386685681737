import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Assignment, ProgressStats, AssignmentProgress, Course } from '../../../types/types';
import { getStudentAssignmentProgress } from '../../../utils/requests';
import Button from '../../core/button/Button/Button';
import Icon from '../../core/display/Icon';
import StudentAssignmentTutorial from '../../tutorial/StudentAssignmentTutorial';
import AssignmentDetailsCard from '../AssignmentDetailsCard';
import PhaseMenu from './PhaseMenu';
import { useParams } from 'react-router';
import PhaseCard from './PhaseCard';

interface Props {
  assignment: Assignment;
  course: Course;
  timeRemaining: string;
}

function StudentDashboard({ assignment, timeRemaining }: Props): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };
  const [currentTaskElement, setCurrentTaskElement] = useState<JSX.Element>(<></>);
  const [userProgress, setAssignmentProgress] = useState<AssignmentProgress | null>(null);
  const user = useSelector((state: RootState) => state.user);
  const rootPath = `/course/${courseId}/assignment/${assignmentId}`;

  useEffect(() => {
    if (assignment && assignment.assignmentId)
      getStudentAssignmentProgress(assignment.assignmentId, setAssignmentProgress);
  }, [assignment]);
  const status = userProgress?.status;

  useEffect(() => {
    if (assignment && userProgress) {
      const task = assignmentCurrentStudentTask(assignment, userProgress);

      if (task.href)
        setCurrentTaskElement(
          <Button id="next-task-btn" className={task.buttonClass} href={task.href} variant="rad" route>
            {task.text}
          </Button>,
        );
      else setCurrentTaskElement(<p>{task.text}</p>);
    }
  }, [assignment, userProgress]);

  return (
    <div id="student-dashboard" className="page">
      <AssignmentDetailsCard assignment={assignment}>
        <div className="curr-task-wrapper">
          {assignment && assignment.progressStats && status ? (
            <>
              {assignment.status === 'ACTIVE' ? (
                <>
                  {status?.hasSubmitted ||
                  assignment.progressStats?.submissionPhase ||
                  status.canLateSubmit ||
                  assignment.reviewingWithoutSubmission ||
                  assignment.instructorGradedOnly ||
                  assignment.peerEvaluationOnly ||
                  assignment.instructorUpload ? (
                    <>
                      <span>Next task:</span>
                      <div className="task-time-wrapper">
                        {currentTaskElement}
                        <b>{timeRemaining} remaining</b>
                      </div>
                    </>
                  ) : (
                    <>
                      <Icon code="error" ariaHidden />
                      <span>Missed submission! Cannot continue assignment</span>
                    </>
                  )}
                </>
              ) : null}
              {assignment.status === 'COMPLETE' ? (
                <div className="to-do-wrapper">
                  <span>Results Available:</span>
                  {currentTaskElement}
                </div>
              ) : null}
              {assignment.asyncEndDeadline !== null ? (
                <>
                  <span>
                    <b>Due: </b>
                  </span>
                  <span>
                    {moment(assignment.asyncEndDeadline).format('M/D/YY')}
                    {` @ `}
                    {moment(assignment.asyncEndDeadline).format('h:mm A')}
                  </span>
                </>
              ) : null}
              {status.showReflection ? (
                <ReflectionCard
                  assignment={assignment}
                  userProgress={userProgress}
                  progressStats={assignment.progressStats}
                  rootPath={rootPath}
                  userId={user.userId}
                />
              ) : null}
            </>
          ) : null}
        </div>
      </AssignmentDetailsCard>
      {userProgress ? <PhaseMenu assignment={assignment} userProgress={userProgress} /> : null}
      {user.actingAs ? null : <StudentAssignmentTutorial />}
    </div>
  );
}

interface PhaseCardProps {
  assignment: Assignment;
  progressStats: ProgressStats;
  rootPath: string;
  userProgress: AssignmentProgress;
}

interface ReflectionCardProps extends PhaseCardProps {
  userId: string;
  progressStats: ProgressStats;
}

function ReflectionCard({ assignment, rootPath, userProgress, progressStats }: ReflectionCardProps): JSX.Element {
  const { instructorGradedOnly } = assignment;
  const { status, instructorReviewReceived } = userProgress;
  const { reflectionPhase } = progressStats;
  const missedClass = !status.hasReflected ? ' fail' : '';
  const completeClass = status.hasReflected ? ' pass' : '';

  const getNoReviewsReceivedText = () => {
    if (assignment.submissionDeadline !== null && moment().isAfter(assignment.submissionDeadline)) {
      if (status.hasSubmitted) {
        if (instructorGradedOnly) {
          return !instructorReviewReceived ? <p>Awaiting instructor review</p> : null;
        } else {
          return (
            <p>
              Reflect action unavailable <br /> (No reviews received)
            </p>
          );
        }
      } else {
        return (
          <p>
            Reflect action unavailable <br /> {status.canLateSubmit ? '(Submission required)' : '(Missed submission)'}
          </p>
        );
      }
    }
  };

  const getReflectionButtonAndText = () => {
    if ((!reflectionPhase || assignment.asyncEnabled) && userProgress.reflection && userProgress.reflection.complete) {
      return (
        <>
          <p className={`completed-text${missedClass}${completeClass}`}>Completed:</p>
          <Button variant="alt" disabled>
            Reflection Complete
          </Button>
        </>
      );
    }

    if (reflectionPhase && status.canReflect) {
      const reflection = userProgress.reflection;

      if (reflection) {
        if (reflection.complete) {
          return (
            <>
              <p className={`completed-text${missedClass}${completeClass}`}>Completed</p>
              <Button
                disabled={!reflectionPhase}
                variant="alt"
                href={`${rootPath}/reflection/${reflection.reflectionId}`}
                route
              >
                Edit Reflection
              </Button>
            </>
          );
        } else {
          return (
            <Button disabled={!reflectionPhase} href={`${rootPath}/reflection/${reflection.reflectionId}`} route>
              Continue Reflection
            </Button>
          );
        }
      } else {
        return (
          <Button disabled={!reflectionPhase} href={`${rootPath}/reflection/new`} route>
            Start Reflection
          </Button>
        );
      }
    }

    if (assignment.asyncEnabled && !status.canReflect && userProgress.reflection == null) {
      return (
        <p>
          Reflect action unavailable <br />
          until reviews are received
        </p>
      );
    }
  };

  const getMissedReflectionText = () => {
    if (userProgress.reviewsReceived > 0) {
      if (
        progressStats.complete &&
        (userProgress.reflection == null || (userProgress.reflection != null && !userProgress.reflection.complete))
      ) {
        return (
          <>
            <p>Missed Reflect Phase</p>
            <Button variant="alt" disabled>
              Incomplete Reflection
            </Button>
          </>
        );
      }
    }
  };

  return (
    <PhaseCard type="REFLECT" role="STUDENT" asyncEnabled={assignment.asyncEnabled} userProgress={userProgress}>
      {userProgress.reviewsReceived > 0 ? <></> : getNoReviewsReceivedText()}
      {getReflectionButtonAndText()}
      {getMissedReflectionText()}
    </PhaseCard>
  );
}

export type TaskStatus = {
  text: string;
  href?: string;
  buttonClass?: string;
};

export const assignmentCurrentStudentTask = (assignment: Assignment, progress: AssignmentProgress): TaskStatus => {
  const { courseId, assignmentId, asyncEnabled, progressStats: stats, groupsEnabled } = assignment;
  const { status } = progress;
  const rootPath = `/course/${courseId}/assignment/${assignmentId}`;

  if (groupsEnabled && !status.inGroup) {
    return { text: 'Create/Join Group', href: `${rootPath}/groups` };
  }

  if (status.hasResult) {
    return { text: 'View Results', href: `${rootPath}/results` };
  }

  if (asyncEnabled ? status.submissionPriority : stats?.submissionPhase) {
    if (status.canSubmit && !status.hasSubmitted) {
      return { text: 'Submit', href: `${rootPath}/submission`, buttonClass: 'submit' };
    } else {
      return { text: 'Submission Complete — Awaiting Review Phase' };
    }
  }

  if (asyncEnabled ? status.reviewPriority : stats?.reviewPhase) {
    if (!status.hasSubmitted && !assignment.instructorUpload) {
      if (status.canLateSubmit) {
        return { text: 'Submit Late', href: `${rootPath}/submission`, buttonClass: 'submit' };
      } else if (!assignment.reviewingWithoutSubmission) {
        return { text: 'Missed Submission — Cannot Review', href: '' };
      }
    }

    const { completedReviews, incompleteReviews, requiredReviewsCount } = progress;
    const requiredSelfReviews = assignment.selfReviewEnabled ? 1 : 0;
    if (status.completedAllReviews && !status.canEvaluate) {
      return { text: 'Reviews Complete — Awaiting Feedback Phase' };
    } else if (status.canEditReviews && incompleteReviews && incompleteReviews.length > 0) {
      return {
        text: 'Continue Review',
        href: `${rootPath}/review/${incompleteReviews[0].reviewId}`,
        buttonClass: 'review',
      };
    } else if (
      completedReviews &&
      incompleteReviews &&
      status.canDoAdditionalReviews &&
      requiredReviewsCount - requiredSelfReviews > completedReviews.length + incompleteReviews.length
    ) {
      if (asyncEnabled && progress.status.waitingForReport === true)
        return { text: 'Waiting for Report Action by Instructor', href: '' };

      let reviewNumber = 1;
      if (progress.completedReviews) reviewNumber += progress.completedReviews.length;
      const submissionLock =
        assignment.reviewingWithoutSubmission && !progress.status.hasSubmitted && reviewNumber === 1;
      const submissionLockParam = submissionLock ? '?submissionLock=true' : '';
      return {
        text: `Start Review ${reviewNumber}`,
        href: `${rootPath}/review/new${submissionLockParam}`,
        buttonClass: 'review',
      };
    }

    if (assignment.selfReviewEnabled && progress.submissionInfo) {
      const { selfReview } = progress;
      if (status.canEditReviews && selfReview) {
        return {
          text: `${!selfReview.complete ? 'Continue' : 'Edit'} Self-Review`,
          href: `${rootPath}/review/${selfReview.reviewId}`,
          buttonClass: 'review',
        };
      } else {
        return {
          text: 'Start Self-Review',
          href: `${rootPath}/review/new?selfReview=true`,
          buttonClass: 'review',
        };
      }
    }
  }

  if (asyncEnabled ? status.feedbackPriority : stats?.feedbackPhase) {
    if (status.completedAllFeedback && !status.canEvaluate) {
      return {
        text: `Feedback Complete — ${assignment.reflectionEnabled ? 'Awaiting Reflect Phase' : 'Awaiting Result'}`,
      };
    }

    const { reviewsRequiringFeedback } = progress;
    if (status.canCompleteFeedback && reviewsRequiringFeedback && reviewsRequiringFeedback.length > 0) {
      return {
        text: 'Start Feedback',
        href: `${rootPath}/review/${reviewsRequiringFeedback[0].reviewId}/feedback?feedbackCompleted=${progress.feedbackCompleted}`,
        buttonClass: 'feedback',
      };
    }
  }

  if (asyncEnabled ? status.peerEvalPriority : stats?.evalPhase) {
    if (status.completedAllEvaluations) {
      return { text: 'Evaluations Complete' };
    }

    if (status.canEvaluate) {
      return {
        text: 'Evaluate',
        href: `${rootPath}/evaluate`,
        buttonClass: 'evaluate',
      };
    }
  }

  if (assignment.reflectionEnabled) {
    if (status.canReflect) {
      if (asyncEnabled ? status.reflectionPriority : stats?.reflectionPhase) {
        if (progress.reflection) {
          if (progress.reflection.complete) {
            return {
              text: 'Reflection Complete - Awaiting Results',
              href: ``,
            };
          } else {
            return {
              text: 'Continue Reflection',
              href: `${rootPath}/reflection/${progress.reflection.reflectionId}`,
            };
          }
        } else {
          return {
            text: 'Start Reflection',
            href: `${rootPath}/reflection/new`,
          };
        }
      }
    } else {
      if (asyncEnabled && progress.reflection && progress.reflection.complete) {
        return {
          text: 'Reflection Complete - Awaiting Results',
          href: ``,
        };
      } else {
        return {
          text: 'Reflect task unavailable',
          href: ``,
        };
      }
    }
  }

  if (asyncEnabled && status.waitingForResults) {
    return { text: 'All Tasks Complete — Awaiting Results', href: '' };
  }

  if (asyncEnabled && status.waitingForReviews) {
    return { text: 'Waiting For Peer Reviews', href: '' };
  }

  if (asyncEnabled && status.waitingForSubmissions) {
    return { text: 'Waiting For Peer Submissions', href: '' };
  }

  return { text: 'Waiting for Available Tasks', href: '' };
};

export default StudentDashboard;
