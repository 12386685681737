import React, { useState, useEffect } from 'react';
import Icon from '../../core/display/Icon';
import { AssignmentWizardPageProps } from '../AssignmentWizard';

export interface SubmissionProps {
  allowFileSubmissions: boolean;
  allowUrlSubmissions: boolean;
  allowTextSubmissions: boolean;
}

function SubmissionPage({
  assignmentSettings,
  updateSettings,
}: AssignmentWizardPageProps<SubmissionProps>): JSX.Element {
  const [allowFileSubmissions, setAllowFileSubmissions] = useState(assignmentSettings.allowFileSubmissions);
  const [allowUrlSubmissions, setAllowUrlSubmissions] = useState(assignmentSettings.allowUrlSubmissions);
  const [allowTextSubmissions, setAllowTextSubmissions] = useState(assignmentSettings.allowTextSubmissions);

  useEffect(() => {
    updateSettings({ allowFileSubmissions, allowUrlSubmissions, allowTextSubmissions });
  }, [updateSettings, allowFileSubmissions, allowUrlSubmissions, allowTextSubmissions]);

  return (
    <>
      <fieldset className="submission-type-menu">
        <legend>Choose the allowed submission types. Click to allow/disallow:</legend>
        <div className="submission-type-btn-wrapper">
          <input
            id="btn-file-type"
            type="checkbox"
            name="submission-type"
            value="file"
            checked={allowFileSubmissions}
            onChange={(e) => {
              setAllowFileSubmissions(e.target.checked);
            }}
          />
          <label htmlFor="btn-file-type">
            <Icon code="description" ariaHidden />
            <span className="type">
              <Icon code={allowFileSubmissions ? 'done' : 'close'} ariaHidden />
              <span className="sr-only">Allow</span> File <span className="sr-only">Submissions</span>
            </span>
          </label>
          <div className="type-description">
            <span>Upload any file type:</span>
            <ul>
              <li>Documents</li>
              <li>Audio</li>
              <li>Video</li>
            </ul>
          </div>
        </div>
        <div className="submission-type-btn-wrapper">
          <input
            id="btn-link-type"
            type="checkbox"
            name="submission-type"
            value="link"
            checked={allowUrlSubmissions}
            onChange={(e) => {
              setAllowUrlSubmissions(e.target.checked);
            }}
          />
          <label htmlFor="btn-link-type">
            <Icon code="link" ariaHidden />
            <span className="type">
              <Icon code={allowUrlSubmissions ? 'done' : 'close'} ariaHidden />
              <span className="sr-only">Allow</span> Link <span className="sr-only">Submissions</span>
            </span>
          </label>
          <div className="type-description">
            <p>Submit links via URL</p>
            <p>Useful for sharing links to cloud storage (i.e. Google Drive)</p>
          </div>
        </div>
        <div className="submission-type-btn-wrapper">
          <input
            id="btn-text-type"
            type="checkbox"
            name="submission-type"
            value="text"
            checked={allowTextSubmissions}
            onChange={(e) => {
              setAllowTextSubmissions(e.target.checked);
            }}
          />
          <label htmlFor="btn-text-type">
            <Icon code="text_fields" ariaHidden />
            <span className="type">
              <Icon code={allowTextSubmissions ? 'done' : 'close'} ariaHidden />
              <span className="sr-only">Allow</span> Text <span className="sr-only">Submissions</span>
            </span>
          </label>
          <div className="type-description">
            <p>Type submissions directly into a Peerceptiv text editor</p>
          </div>
        </div>
        {assignmentSettings.liveMode ? (
          <div className="submission-type-btn-wrapper">
            <input id="btn-presentation-type" type="checkbox" name="submission-type" checked={true} />
            <label htmlFor="btn-presentation-type">
              <Icon code="dataset" />
              <span className="type">Live Presentation</span>
              <div className="type-description">
                <p>Submit live presentation</p>
              </div>
            </label>
          </div>
        ) : null}
      </fieldset>
    </>
  );
}

export default SubmissionPage;
