import React, { useState, useEffect, useCallback } from 'react';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { Course, CourseUser } from '../../types/types';
import Table, { CustomColumn } from '../core/display/Table/Table';
import ExpandedRosterRow from './table_menus/ExpandedRosterRow';

interface Props {
  course: Course;
  showAddModal: () => void;
  studentData: CourseUser[];
  updateData: () => void;
}

type TableData = {
  courseId: string;
  email: string;
  name: string;
  paid: 'Yes' | 'No';
  pseudo: string;
  userId: string;
};

function StudentsTable({ course, showAddModal, studentData, updateData }: Props): JSX.Element {
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableColumns, setTableColumns] = useState<CustomColumn<TableData>[]>([]);

  const { modalDispatch } = useModalContext();

  const handleRowSelect = useCallback(
    (userId: string, courseId: string) =>
      modalDispatch(
        openModal({
          heading: 'Student Details',
          headingHide: true,
          form: false,
          closeButton: true,
          noActionButtons: true,
          children: <ExpandedRosterRow userId={userId} courseId={courseId} updateData={updateData} />,
        }),
      ),
    [modalDispatch, updateData],
  );

  useEffect(() => {
    const columns: CustomColumn<TableData>[] = [
      { Header: 'Name', accessor: 'name', className: 'left-align' },
      { Header: 'Pseudonym', accessor: 'pseudo', className: 'left-align' },
      { Header: 'Email', accessor: 'email', className: 'left-align' },
    ];
    if (course.studentPurchaseEnabled) columns.push({ Header: 'Paid', accessor: 'paid' });
    const dataTable: TableData[] = [];
    studentData.forEach((student: CourseUser) => {
      const newRow: TableData = {
        name: student.user.sortableName,
        pseudo: student.user.pseudonym,
        email: student.user.email,
        paid: student.studentPurchaseRequired ? 'No' : 'Yes',
        userId: student.userId,
        courseId: student.course.courseId as string,
      };

      dataTable.push(newRow);
    });

    setTableData(dataTable);
    setTableColumns(columns);
  }, [studentData, course]);

  return (
    <Table
      columns={tableColumns}
      data={tableData}
      sortBy="name"
      title="Student Roster"
      headingLevel={1}
      id="student-roster-card"
      informOfRow={(row) => handleRowSelect(row.original.userId, row.original.courseId)}
      onAdd={showAddModal}
    />
  );
}

export default StudentsTable;
