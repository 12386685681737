import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../store';
import { EvaluationScoreItemCatalog, CourseUser } from '../../types/types';
import { setPageTitle } from '../../utils/functions';
import { createMemberPeerEval, getRosterStudents } from '../../utils/requests';
import Button from '../core/button/Button/Button';
import Avatar from '../core/display/Avatar/Avatar';
import SearchBar from '../core/input/SearchBar/SearchBar';

interface Props {
  catalog: EvaluationScoreItemCatalog;
  updateCb: () => void;
}

function EvalSelfSelect({ catalog, updateCb }: Props): JSX.Element {
  useEffect(() => setPageTitle('Select Peers to Evaluate'), []);

  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };

  const peerSelectedMap = useMemo(() => {
    const map: { [index: string]: boolean } = {};
    catalog.groupMemberEvalItems.forEach((item) => {
      if (item.targetUser) map[item.targetUser.userId] = true;
    });
    return map;
  }, [catalog]);

  const [peers, setPeers] = useState<CourseUser[]>([]);
  const [searchFilter, setSearchFilter] = useState('');

  const user = useSelector((state: RootState) => state.user);

  const filteredPeers = useMemo(
    () =>
      peers
        .filter((rosterEntry) => {
          if (rosterEntry.user.userId === user.userId) return false;
          if (
            searchFilter !== '' &&
            rosterEntry.user.name?.toLocaleLowerCase().indexOf(searchFilter.toLocaleLowerCase()) === -1
          )
            return false;
          return true;
        })
        .sort((a, b) => (a.user.name || '').localeCompare(b.user.name || '')),
    [peers, searchFilter, user.userId],
  );

  useEffect(() => getRosterStudents(courseId, setPeers), [courseId]);

  return (
    <div id="peer-select-menu">
      <h1>Peer Selection</h1>
      <p>Select peers to evaluate:</p>

      <SearchBar
        placeholder="Search Students"
        value={searchFilter}
        setValue={setSearchFilter}
        resultsLength={filteredPeers.length}
      />

      <div id="peer-select-list">
        {filteredPeers.map((peer) => (
          <div className="peer-entry" key={peer.userId}>
            <span className="left-wrapper">
              <Avatar size={32} user={peer.user} />
              <h2>{peer.user.name}</h2>
            </span>
            {peerSelectedMap[peer.userId] === true ? (
              <Button variant="low rad" disabled>
                Selected
              </Button>
            ) : (
              <Button
                variant="low rad"
                onClick={() => createMemberPeerEval(assignmentId, user.userId, peer.userId, updateCb)}
              >
                Select
              </Button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default EvalSelfSelect;
