import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { EvaluationByItem } from '../../../types/types';
import { getEvalTargetFormatted } from '../../../utils/functions';
import { getEvalItemsByUser } from '../../../utils/requests';
import JumpButton from '../../core/button/JumpButton';
import DeleteEvalButton from './DeleteEvalButton';
import RatingDetailsTable from './RatingDetailsTable';
import { getCompletionStatus, getJumpButtons, StudentDetailsTabProps } from './StudentDetailsPage';
import ReviewDialogue from '../../core/display/ReviewDialogue/ReviewDialogue';

function EvalsSentDetails({ assignmentProgress, updateData, updateKey }: StudentDetailsTabProps): JSX.Element {
  const { assignmentId, userId } = useParams() as { assignmentId: string; userId: string };

  const [evalsSent, setEvalsSent] = useState<EvaluationByItem[] | null>(null);

  useEffect(
    () => getEvalItemsByUser(assignmentId, userId, setEvalsSent),
    [updateKey, assignmentProgress, assignmentId, userId],
  );

  if (evalsSent)
    return (
      <>
        {evalsSent.length > 0 ? (
          <div id="eval-peers-table" className="panel-sm panel-white">
            <h2 className="title" tabIndex={-1}>
              Peers
            </h2>
            <table>
              <tbody>
                {evalsSent.map((peerEvalItem) => (
                  <tr key={`row-${peerEvalItem.peerEvalId}`}>
                    <th>
                      <b>{peerEvalItem.targetUser.name}</b>
                    </th>
                    <td>{getEvalTargetFormatted(peerEvalItem.targetRole)}</td>
                    <td>{getCompletionStatus(peerEvalItem.complete)}</td>
                    <td>{getJumpButtons(`eval-sent-${peerEvalItem.peerEvalId}`, 'Jump to Evaluation')}</td>
                    <td>
                      <DeleteEvalButton peerEvaluationId={peerEvalItem.peerEvalId} updateData={updateData} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="panel-sm panel-white no-progress-message">
            <h2 className="title">No Team Member Evaluations Sent Yet</h2>
            <div>
              <p>
                <b>{assignmentProgress.user.name}</b> has not yet sent team member evaluations for this assignment.
              </p>
              <p>More details will be available once they have sent team member evaluations.</p>
            </div>
          </div>
        )}

        {evalsSent.map((peerEvalItem) => (
          <div
            key={`eval-sent-${peerEvalItem.peerEvalId}`}
            id={`eval-sent-${peerEvalItem.peerEvalId}`}
            className="panel-sm panel-white eval-wrapper"
          >
            <JumpButton type="focus-child" targetId="eval-peers-table" invisible>
              Back to peers table
            </JumpButton>
            <h2 className="title">Peer: {peerEvalItem.targetUser.name}</h2>
            <div className="status-wrapper">
              <span className="target">{getEvalTargetFormatted(peerEvalItem.targetRole)} Evaluation</span>{' '}
              <span>{getCompletionStatus(peerEvalItem.complete)}</span>
            </div>

            {peerEvalItem.comments.length > 0 ? (
              <>
                <h3>Team Member Evaluation Comments</h3>
                {peerEvalItem.comments.map((commentItem) => (
                  <section key={`dialogue-${commentItem.comment.commentId}`} className="dialogue-container">
                    <h4>
                      on &quot;<i>{commentItem.comment.commentName}</i>&quot;
                    </h4>
                    <ReviewDialogue commentWithReviewComments={commentItem} teacherView />
                  </section>
                ))}
              </>
            ) : null}

            {peerEvalItem.ratings.length > 0 ? (
              <RatingDetailsTable heading="Team Member Evaluation Ratings" reviewableByItem={peerEvalItem} />
            ) : null}
          </div>
        ))}
      </>
    );
  return <></>;
}

export default EvalsSentDetails;
