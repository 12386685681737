import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { useTable, useSortBy, usePagination, useGlobalFilter, useAsyncDebounce, Column, Row } from 'react-table';
import { CSVLink } from 'react-csv';
import Pagination from '../../layout/Pagination/Pagination';
import { handleKeySelect, outsideClick, safeRender, storageAvailable } from '../../../../utils/functions';
import SearchBar from '../../input/SearchBar/SearchBar';
import { KEY_TABLE_PAGE_SIZE } from '../../../../utils/constants';
import Icon from '../Icon';
import _ from 'lodash';
import JumpButton from '../../button/JumpButton';
import { HeaderGroup } from 'react-table';

function Table<T extends Record<string, unknown>>({
  children,
  colGroups,
  columns,
  data,
  defaultPageSize,
  getRowProps = () => ({}),
  headerIconMap,
  headingLevel = 2,
  hideDownload = false,
  hideTitle = false,
  hidePagination = false,
  hideSearch = false,
  id,
  informOfRow,
  noWrapper = false,
  onAdd,
  selectedRowContent,
  setOnChangesByGroup = () => undefined,
  setToggleHiddensByGroup = () => undefined,
  sortBy,
  title,
}: {
  children?: React.ReactNode;
  colGroups?: string[][];
  columns: CustomColumn<T>[];
  data: Array<T>;
  defaultPageSize?: number;
  getRowProps?: (row: Row<T>) => { style?: React.CSSProperties };
  headerIconMap?: { [index: string]: string };
  headingLevel?: number;
  hideDownload?: boolean;
  hideTitle?: boolean;
  hidePagination?: boolean;
  hideSearch?: boolean;
  id?: string;
  informOfRow?: (row: Row<T>) => void;
  noWrapper?: boolean;
  onAdd?: () => void;
  selectedRowContent?: JSX.Element | null;
  setOnChangesByGroup?: React.Dispatch<React.SetStateAction<((e: React.ChangeEvent) => void)[][]>>;
  setToggleHiddensByGroup?: React.Dispatch<React.SetStateAction<((value: boolean) => void)[][]>>;
  sortBy?: string;
  title: string;
}): JSX.Element {
  const {
    allColumns,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageOptions,
    preGlobalFilteredRows,
    prepareRow,
    previousPage,
    rows,
    setGlobalFilter,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable<T>(
    {
      columns,
      data,
      initialState: {
        sortBy: [{ id: sortBy ?? '', desc: false }],
        pageSize: parseInt(
          storageAvailable('localStorage') ? window.localStorage.getItem(KEY_TABLE_PAGE_SIZE) || '10' : '10',
        ),
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const uniqueId = useRef(_.uniqueId());
  const tableEl = useRef<HTMLTableElement>(null);
  const theadEl = useRef<HTMLTableSectionElement>(null);
  const tbodyEl = useRef<HTMLTableSectionElement>(null);

  const [selectedRow, setSelectedRow] = useState(-1);
  const [handlerInit, setHandlerInit] = useState(false);

  const colNum = allColumns.length;

  const headersForCsvExport = useMemo<string[]>(() => {
    const headers: string[] = [];
    allColumns.forEach((col) => {
      if (col.Header) headers.push(col.Header as string);
    });
    return headers;
  }, [allColumns]);

  const dataForCsvExport = useMemo<(string | number)[][]>(() => {
    const data: (string | number)[][] = [[]];
    rows.forEach((row) => {
      const newRow: (string | number)[] = [];
      prepareRow(row);
      row.cells.forEach((cell) => newRow.push(cell.value));
      data.push(newRow);
    });
    return data;
  }, [prepareRow, rows]);

  const handleRowSelect = useCallback(
    (e: React.MouseEvent, row: Row<T>) => {
      // informOfRow must be implemented to have selection functionality
      if (informOfRow && theadEl.current) {
        let elem = e.target as HTMLElement;
        if (elem.tagName === 'TD' && elem.parentElement) elem = elem.parentElement;
        if (elem.tagName === 'TR') {
          const numHeadRows = theadEl.current.childElementCount;
          let newSelectedRow = (elem as HTMLTableRowElement).rowIndex - numHeadRows;
          if (selectedRow >= 0 && selectedRow < newSelectedRow) newSelectedRow--;
          setSelectedRow(newSelectedRow);
          informOfRow(row);
        }
      }
    },
    [informOfRow, selectedRow],
  );

  /**
   * Create event listener for deselecting from the selected row
   */
  useEffect(() => {
    // informOfRow must be implemented to have selection functionality
    if (informOfRow) {
      const handleMouseDown = (e: MouseEvent) => {
        if (tbodyEl.current && outsideClick(e, [tbodyEl.current])) {
          setSelectedRow(-1);
        }
      };
      window.addEventListener('mousedown', handleMouseDown);
      return () => window.removeEventListener('mousedown', handleMouseDown);
    }
  }, [informOfRow]);

  useEffect(() => {
    if (colGroups && !handlerInit) {
      const onChanges: (() => void)[][] = [];
      const toggleHiddens: (() => void)[][] = [];
      for (let i = 0; i < colGroups.length; i++) {
        onChanges.push([]);
        toggleHiddens.push([]);
      }
      allColumns.forEach((col) => {
        colGroups.forEach((group, i) => {
          if (group.includes(col.id)) {
            onChanges[i].push(col.getToggleHiddenProps().onChange);
            toggleHiddens[i].push(col.toggleHidden);
          }
        });
      });
      setOnChangesByGroup(onChanges);
      setToggleHiddensByGroup(toggleHiddens);
      setHandlerInit(true);
    }
  }, [allColumns, colGroups, handlerInit, setOnChangesByGroup, setToggleHiddensByGroup]);

  useEffect(() => {
    setSelectedRow(-1);
  }, [page]);

  useEffect(() => {
    if (defaultPageSize) setPageSize(defaultPageSize);
  }, [defaultPageSize, setPageSize]);

  const HeadingTag = `h${headingLevel}` as keyof JSX.IntrinsicElements;

  return (
    <div className={`peer-table-wrapper ${noWrapper ? '' : 'panel-sm panel-white'}`} id={id}>
      <HeadingTag
        id={`table-heading-${uniqueId.current}`}
        className={`title ${noWrapper || hideTitle ? 'sr-only' : ''}`}
      >
        {title}
      </HeadingTag>
      {children}
      <div className="table-ctrls-top">
        {!hideSearch ? (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            resultsLength={rows.length}
          />
        ) : null}
        {defaultPageSize === undefined ? (
          <span className="entries-select-wrapper">
            <select
              aria-label="Table Page Size"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                if (storageAvailable('localStorage'))
                  window.localStorage.setItem(KEY_TABLE_PAGE_SIZE, e.target.value + '');
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>{' '}
            of {rows.length} results
          </span>
        ) : null}
      </div>

      <JumpButton
        invisible
        id={`pre-table-btn-${uniqueId.current}`}
        targetId={`post-table-btn-${uniqueId.current}`}
        type="focus"
      >
        Skip to after table
      </JumpButton>

      <div className="table-scrollable-wrapper">
        <table
          ref={tableEl}
          className="peer-table"
          aria-describedby={`table-heading-${uniqueId.current}`}
          {...getTableProps()}
        >
          <thead ref={theadEl}>
            {headerGroups.map((headerGroup) => {
              const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
              const isGroupRow = headerGroup.headers.some((header) => header.columns !== undefined);
              return (
                <tr key={key} {...restHeaderGroupProps} aria-hidden={isGroupRow}>
                  {headerGroup.headers.map((column) => {
                    const { key, ...restHeaderProps } = column.getHeaderProps(column.getSortByToggleProps());
                    let sortClassName = '';
                    if (column.isSorted)
                      if (column.isSortedDesc) sortClassName = 'descending';
                      else sortClassName = 'ascending';
                    const isPlaceholder = column.placeholderOf !== undefined;
                    return (
                      <th
                        key={key}
                        className={(column as HeaderGroup<T> & { className?: string }).className}
                        {...restHeaderProps}
                        tabIndex={!isPlaceholder ? 0 : -1}
                      >
                        <div className="table-header-wrapper">
                          {column.parent ? <span className="sr-only">{column.parent.id}</span> : null}
                          {headerIconMap ? (
                            <>
                              <Icon className="header-icon" code={headerIconMap[column.id]} />
                              <span>{safeRender(() => column.render('Header'))}</span>
                            </>
                          ) : (
                            <span className="header-title">{safeRender(() => column.render('Header'))}</span>
                          )}
                          <span
                            className={`sort-arrows ${column.isSorted ? sortClassName : 'sr-only'}`}
                            role="button"
                            aria-label={`Sort by ${column.parent ? `${column.parent.id} ` : ''}${column
                              .render('Header')
                              ?.toString()} ${sortClassName}`}
                            onKeyDown={(e) => handleKeySelect(e, () => column.toggleSortBy())}
                            tabIndex={!isPlaceholder ? 0 : -1}
                          >
                            <Icon code="switch_left" ariaHidden />
                          </span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody ref={tbodyEl} {...getTableBodyProps()}>
            {page.length > 0 ? (
              page.map((row, i) => {
                prepareRow(row);
                const { key, ...restRowProps } = row.getRowProps(getRowProps(row));
                const newRow = (
                  <tr
                    key={key}
                    className={i === selectedRow ? 'selected-row' : undefined}
                    {...restRowProps}
                    onClick={(e: React.MouseEvent) => handleRowSelect(e, row)}
                  >
                    {row.cells.map((cell) => {
                      const { key, ...restCellProps } = cell.getCellProps();
                      return (
                        <td
                          key={key}
                          {...restCellProps}
                          className={(cell.column as CustomColumn<T>).className}
                          aria-label={`${cell.column.parent?.Header ?? ''} ${cell.column.Header}: ${
                            cell.column.Cell && typeof cell.render('Cell') === 'string'
                              ? cell.column.render('Cell')
                              : cell.value
                          };`}
                        >
                          {safeRender(() => cell.render('Cell'))}
                        </td>
                      );
                    })}
                    {informOfRow ? (
                      <td
                        className="sr-only sr-show-on-focus"
                        role="button"
                        tabIndex={0}
                        onKeyDown={(e) => handleKeySelect(e, () => informOfRow(row))}
                      >
                        Select Row {i + 1} ({row.cells[0].column.Header}: {row.cells[0].value})
                      </td>
                    ) : null}
                  </tr>
                );
                if (i === selectedRow)
                  return [
                    newRow,
                    <tr key={`expanded-row-${i}`} className="row-more-content">
                      <td colSpan={colNum}>{selectedRowContent}</td>
                    </tr>,
                  ];
                return newRow;
              })
            ) : (
              <tr>
                <td align="center" colSpan={99}>
                  No results available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <JumpButton
        invisible
        id={`post-table-btn-${uniqueId.current}`}
        targetId={`pre-table-btn-${uniqueId.current}`}
        type="focus"
      >
        Skip to before table
      </JumpButton>

      {!hidePagination ? (
        <div className="pagination-wrapper">
          <Pagination
            currPage={pageIndex}
            pageCount={pageOptions.length}
            nextPage={nextPage}
            prevPage={previousPage}
            goToPage={gotoPage}
            uniqueLabel={title ? `${title} Table` : undefined}
          />
        </div>
      ) : null}

      {onAdd ? (
        <div className="table-ctrls-bottom-left">
          <button className="circ-btn" onClick={onAdd}>
            <Icon code="add" />
          </button>
        </div>
      ) : (
        ''
      )}
      {!hideDownload ? (
        <div className="table-ctrls-bottom-right">
          <CSVLink
            headers={headersForCsvExport}
            data={dataForCsvExport}
            filename={'my-file.csv'}
            className="circ-btn"
            target="_blank"
          >
            <Icon code="download" />
          </CSVLink>
        </div>
      ) : null}
    </div>
  );
}

function GlobalFilter<T extends Record<string, unknown>>({
  globalFilter,
  resultsLength,
  setGlobalFilter,
}: {
  globalFilter: string;
  preGlobalFilteredRows: Row<T>[];
  resultsLength: number;
  setGlobalFilter: (filterValue: string) => void;
}): JSX.Element {
  const [value, setValue] = React.useState(globalFilter || '');
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <SearchBar
      value={value}
      setValue={(value) => {
        setValue(value);
        onChange(value);
      }}
      resultsLength={resultsLength}
    />
  );
}

export const getCellWithUnit = (value: number | string, unit: string, indicators?: JSX.Element[]): JSX.Element => {
  return (
    <>
      <span>{value}</span>
      <span className="data-unit">{unit}</span>
      {indicators}
    </>
  );
};

export const getCellWithIndicators = (value: number | string, indicators?: JSX.Element[]): JSX.Element => {
  return (
    <>
      <span>{value}</span>
      {indicators}
    </>
  );
};

export type CustomColumn<D extends Record<string, unknown>> = Column<D> & { className?: string };

export default Table;
