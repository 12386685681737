import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { CourseUser } from '../../types/types';
import { getUserCourseMemberships } from '../../utils/requests';
import Icon from '../core/display/Icon';
import QueryTable, { Column } from '../core/display/QueryTable/QueryTable';
import CourseCard from './CourseCard';

interface Props {
  ctrlsInsert?: React.ReactNode;
  onArchive?: (courseId: string) => void;
  onActivate?: (courseId: string) => void;
  onDelete?: (courseId: string) => void;
  onLeave?: (courseId: string) => void;
  onRowSelect: (rowDatum: CourseUser) => void;
  updateKey?: number | undefined;
}

function CourseListTable({
  ctrlsInsert,
  onArchive,
  onActivate,
  onDelete,
  onLeave,
  onRowSelect,
  updateKey,
}: Props): JSX.Element {
  const ghostCourses = Array.from({ length: 8 }, (v, i) => <CourseGhost key={`ghost-${i}`} index={i} />);

  const getCourseCardElem = useCallback(
    (courseUser: CourseUser, i: number) => {
      const course = courseUser.course;
      return (
        <CourseCard
          key={`${i}-course-${course.courseId}`}
          id={course.courseId}
          name={course.courseName}
          semester={course.semester}
          year={course.year}
          discipline={course.discipline}
          university={course.university}
          timeZone={course.timeZone}
          label={course.label}
          password={course.enrollmentPassword}
          rosterStudentCount={course.rosterStudentCount}
          asyncEnabled={course.asyncEnabled}
          studentPurchaseEnabled={course.studentPurchaseEnabled}
          status={course.status}
          style={{ zIndex: Math.abs(6000 - i) }}
          onSelect={() => onRowSelect(courseUser)}
          onArchive={onArchive}
          onActivate={onActivate}
          onDelete={onDelete}
          onLeave={onLeave}
          role={courseUser.role}
        />
      );
    },
    [onRowSelect, onArchive, onActivate, onDelete, onLeave],
  );

  const tableColumns = useMemo<Column<CourseUser>[]>(
    () => [
      {
        Header: 'Course Name',
        accessor: 'course',
        className: 'left-align',
        Cell: (courseUser: CourseUser) => courseUser.course.courseName,
        customSort: 'courseName',
      },
      {
        Header: 'Created By',
        accessor: 'course',
        className: 'left-align',
        Cell: (courseUser: CourseUser) => {
          const { course } = courseUser;
          if (course.user && course.user.name) return course.user.name;
          return '';
        },
      },
      {
        Header: 'Created At',
        accessor: 'course',
        Cell: (courseUser: CourseUser) => moment(courseUser.course.createdAt).format('MMM DD, YYYY'),
        customSort: 'createdAt',
      },
      {
        Header: 'Roster',
        accessor: 'course',
        className: 'center-align',
        Cell: function render(courseUser: CourseUser) {
          return (
            <span>
              <Icon code="person" ariaHidden />
              {courseUser.course.rosterStudentCount ?? 0}
            </span>
          );
        },
      },
      {
        Header: 'Type',
        accessor: 'course',
        className: 'center-align',
        Cell: function render(courseUser: CourseUser) {
          if (courseUser.course.asyncEnabled)
            return <Icon className="async-icon" code="timer_off" label="Asynchronous" tooltip />;
          return <Icon className="sync-icon" code="update" label="Synchronous" tooltip />;
        },
        customSort: 'asyncEnabled',
      },
    ],
    [],
  );

  return (
    <QueryTable
      title="Courses"
      id="course-list-table"
      ctrlsInsert={ctrlsInsert}
      columns={tableColumns}
      types={[
        { buttonText: `Active`, type: 'ACTIVE' },
        { buttonText: `Archived`, type: 'ARCHIVED' },
        { buttonText: `All`, type: null },
      ]}
      queryRequest={getUserCourseMemberships}
      onRowSelect={onRowSelect}
      updateKey={updateKey}
      classNames={{
        ctrlsClassName: 'ctrls-row',
        ctrlsTypesClassName: 'col-3',
        ctrlsWrapperClassName: 'col-3',
        tableClassName: 'home-body',
      }}
      itemViewEnable
      itemViewDefault={true}
      itemContainerClassName="item-container"
      itemViewRender={getCourseCardElem}
      itemViewRenderLoading={ghostCourses}
      hideLimitSelect
      defaultPageSize={10}
    />
  );
}

function CourseGhost({ index }: { index: number }): JSX.Element {
  return <div className="course-ghost ghost" style={{ animationDelay: `${index * 0.2}s` }} />;
}

export default CourseListTable;
