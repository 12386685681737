import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { RootState } from '../../store';
import { RubricTemplate } from '../../types/types';
import { formDataToObject, setPageTitle, timeDiff } from '../../utils/functions';
import {
  copyRubricTemplate,
  createRubricTemplate,
  deleteRubricTemplate,
  getUserRubricTemplates,
  updateRubricTemplate,
} from '../../utils/requests';
import Button from '../core/button/Button/Button';
import Dropdown from '../core/button/Dropdown/Dropdown';
import Icon from '../core/display/Icon';
import QueryTable, { Column } from '../core/display/QueryTable/QueryTable';
import ShareRubricTemplateMenu from './ShareRubricTemplateMenu';

function RubricLibrary(): JSX.Element {
  useEffect(() => setPageTitle('Rubric Library'), []);

  const { assignmentId } = useParams() as { assignmentId?: string };

  const [loadingFavorites, setLoadingFavorites] = useState<string[]>([]);
  const [updateKey, setUpdateKey] = useState(0);

  const updateData = useCallback(() => setUpdateKey((prevKey) => prevKey + 1), []);

  const user = useSelector((state: RootState) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const { modalDispatch } = useModalContext();

  const baseUrl = useMemo(() => {
    let path = location.pathname;
    if (path.charAt(path.length - 1) === '/') path = path.substring(0, path.length - 1);
    return path;
  }, [location]);

  const handleRowSelect = useCallback(
    (rubricTemplate: RubricTemplate) => navigate(`${baseUrl}/rubric/${rubricTemplate.rubricTemplateId}`),
    [navigate, baseUrl],
  );

  const toggleFavorite = useCallback(
    (rubric: RubricTemplate) => {
      const { rubricTemplateId, favorite } = rubric;
      setLoadingFavorites((prevStatus) => [...prevStatus, rubricTemplateId]);
      updateRubricTemplate(rubricTemplateId, { ...rubric, favorite: !favorite }, () => {
        requestAnimationFrame(() => setLoadingFavorites([]));
        updateData();
      });
    },
    [updateData],
  );

  const handleDelete = useCallback(
    (rubricTemplateId: string) =>
      modalDispatch(
        openModal({
          heading: 'Delete Rubric',
          label: 'Are you sure you want to delete this rubric?',
          buttonText: 'Delete',
          padding: '3rem',
          onConfirm: () => deleteRubricTemplate(rubricTemplateId, updateData),
        }),
      ),
    [modalDispatch, updateData],
  );

  const handleCopy = useCallback(
    (rubricTemplateId: string) =>
      copyRubricTemplate(rubricTemplateId, (newRubric) =>
        navigate(`/rubrics/library/rubric/${newRubric.rubricTemplateId}`),
      ),
    [navigate],
  );

  const handleRename = useCallback(
    (rubric: RubricTemplate) =>
      modalDispatch(
        openModal({
          heading: 'Rename Rubric',
          label: 'Enter the new rubric name:',
          inputType: 'text',
          onSubmit: (formData) => {
            const name = formDataToObject(formData).result;
            updateRubricTemplate(rubric.rubricTemplateId, { ...rubric, name }, updateData);
          },
        }),
      ),
    [modalDispatch, updateData],
  );

  const handleShare = useCallback(
    (rubricTemplate: RubricTemplate) => {
      modalDispatch(
        openModal({
          heading: 'Share Rubric Template',
          closeButton: true,
          noActionButtons: true,
          form: false,
          children: <ShareRubricTemplateMenu rubricTemplate={rubricTemplate} updateData={updateData} />,
        }),
      );
    },
    [modalDispatch, updateData],
  );

  const handleNewRubric = useCallback(
    () =>
      createRubricTemplate({ name: `New Rubric` }, (rubricTemplate) =>
        navigate(`/rubrics/library/rubric/${rubricTemplate.rubricTemplateId}`),
      ),
    [navigate],
  );

  const isImport = assignmentId !== undefined;

  const columns = useMemo<Column<RubricTemplate>[]>(() => {
    const cols: Column<RubricTemplate>[] = [
      { Header: 'Rubric Name', accessor: 'name', className: 'left-align' },
      {
        Header: 'Created By',
        accessor: 'user',
        Cell: (rubricTemplate: RubricTemplate) => (rubricTemplate.user.admin ? 'Peerceptiv' : rubricTemplate.user.name),
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: (rubricTemplate: RubricTemplate) => moment(rubricTemplate.createdAt).format('MMM D, YYYY'),
        defaultSort: true,
        sortDirection: 'DESC',
      },
      {
        Header: 'Last Modified',
        accessor: 'updatedAt',
        Cell: (rubricTemplate: RubricTemplate) => timeDiff(rubricTemplate.updatedAt ?? ''),
        className: 'center-align',
      },
      {
        Header: 'Prompts',
        accessor: 'items',
        Cell: (rubricTemplate: RubricTemplate) => rubricTemplate.items.length,
        className: 'center-align',
        notSortable: true,
      },
      {
        Header: 'Uses',
        accessor: 'uses',
        className: 'center-align',
        notSortable: true,
      },
      {
        Header: <Icon code="star_outline" label="Favorite" tooltip />,
        accessor: 'favorite',
        Cell: function cell(rubricTemplate: RubricTemplate) {
          if (rubricTemplate.favorite) return <Icon code="star" label="Favorited" tooltip color="#FFD23E" />;
          return <Icon code="star_outline" label="Not Favorited" tooltip style={{ opacity: 0.1 }} />;
        },
        className: 'center-align',
      },
    ];

    if (!isImport)
      cols.push({
        Header: 'Options',
        accessor: 'rubricTemplateId',
        Cell: function OptionsCell(rubricTemplate: RubricTemplate) {
          const accessibleLabelDiv = <div className="sr-only">{`"${rubricTemplate.name}"`}</div>;
          return (
            <Dropdown
              ariaLabel={`Options for "${rubricTemplate.name}"`}
              className="button-mini options-btn"
              iconCode="more_horiz"
              align="right"
            >
              <Dropdown.Link href="#" onClick={() => handleCopy(rubricTemplate.rubricTemplateId)}>
                Copy {accessibleLabelDiv}
              </Dropdown.Link>
              {rubricTemplate.user.userId === user.userId || user.admin ? (
                <>
                  <Dropdown.Link href="#" onClick={() => handleRename(rubricTemplate)}>
                    Rename {accessibleLabelDiv}
                  </Dropdown.Link>
                  <Dropdown.Link href="#" onClick={() => handleShare(rubricTemplate)}>
                    Share {accessibleLabelDiv}
                  </Dropdown.Link>
                  <Dropdown.Link href="#" onClick={() => handleDelete(rubricTemplate.rubricTemplateId)}>
                    Delete {accessibleLabelDiv}
                  </Dropdown.Link>
                </>
              ) : null}
            </Dropdown>
          );
        },
        className: 'center-align options-cell',
        notSortable: true,
      });

    return cols;
  }, [isImport, user, handleCopy, handleRename, handleShare, handleDelete]);

  return (
    <>
      <h1 className="sr-only">Rubric Templates</h1>
      {isImport ? (
        <>
          <Button id="copy-return-btn" onClick={() => navigate(-1)}>
            Return to Rubric
          </Button>
          <p>
            <b>Select a rubric template to import:</b>
          </p>
        </>
      ) : null}
      <QueryTable
        title="Rubric Templates"
        ctrlsInsert={
          <div className="col-2">
            <Button variant="low sm" onClick={handleNewRubric}>
              <Icon code="add" ariaHidden /> New Template
            </Button>
          </div>
        }
        columns={columns}
        queryRequest={getUserRubricTemplates}
        onRowSelect={handleRowSelect}
        updateKey={updateKey}
        classNames={{
          ctrlsClassName: 'ctrls-row',
          ctrlsWrapperClassName: 'col-2',
          tableClassName: 'home-body',
        }}
      />
    </>
  );
}

export default RubricLibrary;
